import * as React from "react";
import styled from "styled-components";

const SvgFemale16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 62.5 100" {...props}>
    <path d="M31.2 12.5c-10.4 0-18.8 8.4-18.8 18.8s8.4 18.8 18.8 18.8S50 41.6 50 31.2c0-10.3-8.4-18.7-18.8-18.7zm0-12.5c17.3 0 31.2 14 31.2 31.2 0 15.1-10.7 27.7-25 30.6v13.1h6.2c3.5 0 6.2 2.8 6.2 6.2s-2.8 6.2-6.2 6.2h-6.2v6.2c0 3.5-2.8 6.2-6.2 6.2S25 97.2 25 93.8v-6.2h-6.2c-3.5 0-6.2-2.8-6.2-6.2s2.8-6.2 6.2-6.2H25V62.1C10.7 59 0 46.4 0 31.2 0 14 14 0 31.2 0z" />
  </svg>
);

export default styled(SvgFemale16)``;
