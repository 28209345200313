import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import {
  StandardTextFieldProps,
  TextFieldProps,
} from "@material-ui/core/TextField";
import React, { useState } from "react";
import { IconEyeClose16, IconEyeOpen16 } from "./icons-ts";
import { colors } from "./lib/constants";

export interface IInputProps extends StandardTextFieldProps {
  children?: React.ReactNode;
}

const InputPassword = (props: TextFieldProps) => {
  const [show, setShow] = useState(false);

  return (
    <TextField
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => setShow(!show)}
          >
            {show ? (
              <IconEyeOpen16 fill={colors.darkDk} width={16} height={10} />
            ) : (
              <IconEyeClose16 fill={colors.darkDk} width={16} height={14} />
            )}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

export const Input = ({
  type,
  icon,
  ...props
}: {
  icon?: React.ReactNode;
} & TextFieldProps) => {
  return (
    <>
      {type === "password" ? (
        <InputPassword {...props} />
      ) : (
        <TextField
          type={type}
          {...props}
          InputProps={{
            ...props.InputProps,
            endAdornment: icon && (
              <InputAdornment position="end" style={{ marginRight: 3 }}>
                {icon}
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};
