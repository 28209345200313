import * as React from "react";
import styled from "styled-components";

const SvgArrowR10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 50 100" {...props}>
    <path
      className="arrow-r-10_svg__st0"
      d="M18.6 96.8l28.2-39c4.3-4.3 4.3-11.2 0-15.5l-28.2-39C14.4-1 7.5-1 3.2 3.3-1 7.6-1 14.5 3.2 18.8L23.7 50 3.2 81.3C-1 85.6-1 92.5 3.2 96.8c2.1 2.1 4.9 3.2 7.7 3.2s5.6-1.1 7.7-3.2z"
    />
  </svg>
);

export default styled(SvgArrowR10)``;
