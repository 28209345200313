import * as React from "react";
import styled from "styled-components";

const SvgInfo16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path
      className="info-16_svg__st0"
      d="M50 25c1.7 0 3.3.6 4.4 1.8 1.2 1.2 1.8 2.7 1.8 4.4 0 .8-.2 1.6-.5 2.4s-.8 1.4-1.3 2.1c-.6.6-1.3 1-2.1 1.3-1.1.5-2.4.6-3.6.4-.4-.1-.8-.2-1.1-.4-.4-.1-.8-.3-1.1-.6-.4-.2-.7-.5-1-.8-.6-.6-1-1.3-1.3-2.1s-.5-1.6-.5-2.4c0-1.7.6-3.3 1.8-4.4 1.3-1.1 2.8-1.7 4.5-1.7zm0 18.8c3.5 0 6.2 2.8 6.2 6.2v18.8c0 3.5-2.8 6.2-6.2 6.2s-6.2-2.8-6.2-6.2V50c0-3.5 2.7-6.2 6.2-6.2zM50 0c27.6 0 50 22.4 50 50s-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0zm0 87.5c20.7 0 37.5-16.8 37.5-37.5S70.7 12.5 50 12.5 12.5 29.3 12.5 50 29.3 87.5 50 87.5z"
    />
  </svg>
);

export default styled(SvgInfo16)``;
