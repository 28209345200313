import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div<{ offset?: number }>`
  margin-top: 30px;

  ${p =>
    p.offset &&
    css`
      margin-top: ${p.offset}px;
    `}
`;

export const Fieldset = ({
  children,
  offset,
}: {
  children: React.ReactNode;
  offset?: number;
}) => <Container offset={offset}>{children}</Container>;
