export const pointsToCouronnes = (points?: number | string | null): number => {
  points = typeof points === "undefined" || points === null ? 0 : points;
  points = typeof points === "string" ? parseInt(points, 10) : points;
  if (isNaN(points)) {
    return 0;
  }
  return Math.floor(points / 50);
};

export const couronnesToPoints = (couronnes?: number): number => {
  if (!couronnes) {
    return 0;
  }
  return couronnes * 50;
};

// les transactions avant 15/06/2024 soit en couronnes (et mention historique en points)
export const shouldShowPoints = (date: Date): boolean => {
  return date < new Date("2024-06-15");
}
