import { useUser } from "@igloo-be-omnipartners/hooks";
import React from "react";
import styled from "styled-components";
import { Link } from "./Link";

const Banner = styled.div`
  background-color: #eee;
  padding: 18px 0;
  text-align: center;
  font-size: 12px;
  width: 100%;
`;

const LogOutLink = styled(Link)`
  font-size: 12px;
  font-weight
`;

export interface ILoginAsBannerProps {
  onClick: () => void;
}

export const LoginAsBanner = ({ onClick }: ILoginAsBannerProps) => {
  const { user } = useUser();
  const email = (user && user.owner && user.owner.email) || "";

  return (
    <>
      <Banner>
        Vous êtes loggé en tant que <b>{email}</b>
        {" - "}
        <LogOutLink onClick={onClick}>Se déconnecter</LogOutLink>
      </Banner>
    </>
  );
};
