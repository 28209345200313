import * as React from "react";
import styled from "styled-components";

const SvgEyeOpen16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 101 89" {...props}>
    <path d="M51 31.8c6.9 0 12.5 5.6 12.5 12.5S57.9 56.8 51 56.8s-12.5-5.6-12.5-12.5S44.1 31.8 51 31.8zm0-6.3c-13.9 0-27.9 11.3-35.3 18.8C21.9 50.5 36.2 63 51 63c13.9 0 27.9-11.2 35.3-18.8C80.1 38 65.8 25.5 51 25.5zM51 13c26 0 47.7 26.2 48.6 27.3 1.9 2.3 1.9 5.6 0 7.9C98.7 49.3 77 75.5 51 75.5S3.3 49.3 2.4 48.2c-1.9-2.3-1.9-5.6 0-7.9C3.3 39.2 25 13 51 13z" />
  </svg>
);

export default styled(SvgEyeOpen16)``;
