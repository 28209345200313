// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-catalog-detail-tsx": () => import("./../src/pages/catalog-detail.tsx" /* webpackChunkName: "component---src-pages-catalog-detail-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-litters-ad-tsx": () => import("./../src/pages/litters/ad.tsx" /* webpackChunkName: "component---src-pages-litters-ad-tsx" */),
  "component---src-pages-litters-choice-tsx": () => import("./../src/pages/litters/choice.tsx" /* webpackChunkName: "component---src-pages-litters-choice-tsx" */),
  "component---src-pages-litters-create-tsx": () => import("./../src/pages/litters/create.tsx" /* webpackChunkName: "component---src-pages-litters-create-tsx" */),
  "component---src-pages-litters-index-tsx": () => import("./../src/pages/litters/index.tsx" /* webpackChunkName: "component---src-pages-litters-index-tsx" */),
  "component---src-pages-litters-kits-tsx": () => import("./../src/pages/litters/kits.tsx" /* webpackChunkName: "component---src-pages-litters-kits-tsx" */),
  "component---src-pages-litters-success-tsx": () => import("./../src/pages/litters/success.tsx" /* webpackChunkName: "component---src-pages-litters-success-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-points-tsx": () => import("./../src/pages/points.tsx" /* webpackChunkName: "component---src-pages-points-tsx" */)
}

