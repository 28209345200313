import { Dialog, Slide, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { mediaQuery } from "./lib/media";

const Content = styled.div`
  padding: 40px;

  ${mediaQuery("phablet")`
    padding: 20px 15px;
  `}
`;

export const Modal = ({
  open,
  onClose,
  children,
  maxWidth = "md",
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  maxWidth?: React.ComponentProps<typeof Dialog>["maxWidth"];
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={useMediaQuery(theme.breakpoints.down("sm"))}
      fullWidth
      maxWidth={maxWidth}
    >
      <Slide in={open} direction="down">
        <Content>{children}</Content>
      </Slide>
    </Dialog>
  );
};
