export { fontSize, fontFace, fontFamily } from "./cssUtils";

const interpolateDefault = (v: any) => (v && v.default) || v;

export const ffdinPro = {
  regular: {
    name: "FF Din Pro-regular",
    weight: "400",
    woff: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-regular.woff"),
    ),
    woff2: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-regular.woff2"),
    ),
  },

  medium: {
    name: "FF Din Pro-medium",
    weight: "500",
    woff: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-medium.woff"),
    ),
    woff2: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-medium.woff2"),
    ),
  },

  bold: {
    name: "FF Din Pro-bold",
    weight: "600",
    woff: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-bold.woff"),
    ),
    woff2: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-bold.woff2"),
    ),
  },

  condensedMedium: {
    name: "FF Din Pro Condensed-medium",
    weight: "500",
    woff: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-medium-condensed.woff"),
    ),
    woff2: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-medium-condensed.woff2"),
    ),
  },

  condensedBold: {
    name: "FF Din Pro Condensed-bold",
    weight: "600",
    woff: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-bold-condensed.woff"),
    ),
    woff2: interpolateDefault(
      require("../../assets/fonts/ffdinpro/ffdinpro-bold-condensed.woff2"),
    ),
  },
};
