import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import React from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { styledFlexboxGrid } from "./lib/constants";
import { muiTheme } from "./lib/muiTheme";
import { PickersUtilsProvider } from "./PickersUtilsProvider";

const StyledThemeProvider = (props: { children: React.ReactChild }) => (
  <SCThemeProvider theme={{ flexboxgrid: styledFlexboxGrid }} {...props} />
);

export const UIKitProvider = ({ children }: { children: React.ReactNode }) => (
  <PickersUtilsProvider>
    <StylesProvider injectFirst>
      <StyledThemeProvider>
        <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  </PickersUtilsProvider>
);
