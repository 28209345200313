import * as React from "react";
import styled from "styled-components";

const SvgUpload16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M0 68.7c0-3.5 2.8-6.3 6.2-6.3s6.2 2.8 6.2 6.3V75c0 6.9 5.6 12.5 12.5 12.5h50c6.9 0 12.5-5.6 12.5-12.5v-6.2c0-3.5 2.8-6.3 6.2-6.3s6.2 2.8 6.2 6.3V75c0 13.8-11.2 25-25 25H25C11.2 100 0 88.8 0 75v-6.3zm52.1 5.9c-1.4.6-3.1.6-4.5 0-.6-.3-1.1-.6-1.7-.9L27.2 61.1c-2.6-2.2-2.9-6-.8-8.7s5.9-3.1 8.6-1.1l8.6 4.4V6.3c0-3.5 2.8-6.2 6.2-6.2 3.5 0 6.3 2.8 6.3 6.2v49.5l8.6-4.3c1.7-1.5 4.1-1.9 6.3-1.1 2.1.8 3.7 2.7 4 5 .3 2.3-.6 4.5-2.4 5.9L53.7 73.7c-.3.2-.6.4-.9.5-.2.1-.4.3-.7.4z" />
  </svg>
);

export default styled(SvgUpload16)``;
