import * as React from "react";
import styled from "styled-components";

const SvgQuestion16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} {...props}>
    <path d="M8 14c3.309 0 6-2.691 6-6s-2.691-6-6-6-6 2.691-6 6 2.691 6 6 6zM8 0c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8zm.7 10.254l.059.005c.056.011.106.04.15.085a.298.298 0 01.082.154l.005.06v1.138l-.005.06a.298.298 0 01-.082.154.284.284 0 01-.15.084L8.7 12H7.32l-.058-.006a.284.284 0 01-.15-.084.298.298 0 01-.082-.154l-.005-.06v-1.138l.005-.06a.298.298 0 01.082-.154.284.284 0 01.15-.085l.058-.005h1.38zM8.045 4c.409 0 .794.058 1.155.175.361.116.675.278.942.484.266.207.476.45.63.733.152.281.229.591.229.93 0 .27-.044.506-.131.709a2.323 2.323 0 01-.334.552 3.144 3.144 0 01-.444.445c-.16.132-.315.261-.465.389a3.79 3.79 0 00-.405.4c-.09.104-.16.216-.207.335l-.04.121a.58.58 0 01-.104.214.226.226 0 01-.134.085l-.057.005H7.343l-.058-.005a.284.284 0 01-.15-.085.297.297 0 01-.088-.214c0-.248.05-.47.148-.665a2.42 2.42 0 01.367-.535c.146-.161.305-.31.476-.445l.326-.263c.052-.042.102-.084.15-.126.147-.124.269-.25.367-.377a.668.668 0 00.148-.417.612.612 0 00-.24-.513c-.161-.124-.41-.186-.745-.186-.329 0-.564.051-.706.153a1.017 1.017 0 00-.312.332l-.12.228a4.89 4.89 0 00-.05.104.254.254 0 01-.202.146l-.077.006H5.24l-.063-.007A.237.237 0 015 6.468a2.05 2.05 0 01.219-.829 2.71 2.71 0 01.597-.8c.259-.244.575-.445.947-.602.372-.158.799-.237 1.28-.237z" />
  </svg>
);

export default styled(SvgQuestion16)``;
