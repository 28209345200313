import * as React from "react";
import styled from "styled-components";

const SvgRedirect14 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M85.7 24.8L55.6 55c-2.9 2.9-7.6 2.9-10.6 0-2.9-2.9-2.9-7.6 0-10.6l30.1-30.1H64.3c-3.9 0-7.1-3.2-7.1-7.1S60.3 0 64.3 0h28.6c2 0 3.8.8 5.1 2.1 1.3 1.3 2.1 3.1 2.1 5.1v28.6c0 3.9-3.2 7.1-7.1 7.1-3.9 0-7.1-3.2-7.1-7.1v-11zm-50-17.7c0 3.9-3.2 7.1-7.1 7.1h-7.1c-3.9 0-7.1 3.2-7.1 7.1v57.1c0 3.9 3.2 7.1 7.1 7.1h57.1c3.9 0 7.1-3.2 7.1-7.1v-7.1c0-3.9 3.2-7.1 7.1-7.1 3.9 0 7.1 3.2 7.1 7.1v7.1c0 11.8-9.6 21.4-21.4 21.4H21.4C9.6 100 0 90.4 0 78.6V21.4C0 9.6 9.6 0 21.4 0h7.1c4 0 7.2 3.2 7.2 7.1z" />
  </svg>
);

export default styled(SvgRedirect14)``;
