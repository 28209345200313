import * as React from "react";
import styled from "styled-components";

const SvgPicture16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Layer 1" viewBox="0 0 100 100" {...props}>
    <path d="M75 12.5H25A12.5 12.5 0 0012.5 25v31.25L25 43.75l14.33 14.33a6.25 6.25 0 008.84 0L75 31.25l12.5 12.5V25A12.5 12.5 0 0075 12.5zm0 36.43l-18 18a18.75 18.75 0 01-26.52 0L25 61.43l-12.5 12.5V75A12.5 12.5 0 0025 87.5h50A12.5 12.5 0 0087.5 75V61.43zM75 0a25 25 0 0125 25v50a25 25 0 01-25 25H25A25 25 0 010 75V25A25 25 0 0125 0z" />
  </svg>
);

export default styled(SvgPicture16)``;
