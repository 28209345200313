import React from "react";
import ContentLoader from "react-content-loader";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { mediaQuery } from "./lib/media";

const ColStyled = styled(Col)`
  display: flex !important;
  flex-direction: column;

  ${mediaQuery("desktop")`
    &:not(:nth-child(-n + 3)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("tablet")`
    &:not(:nth-child(-n + 2)) {
      margin-top: 20px;
    }
  `}

  ${mediaQuery("phablet")`
    flex-basis: 100%;
    max-width: 100%;
    display: block;

    &:not(:first-child) {
      margin-top: 20px;
    }
  `}
`;

const CardPlaceholder = () => (
  <div style={{ width: "100%" }}>
    <ContentLoader
      style={{ width: "100%" }}
      height={350}
      width={240}
      backgroundColor={colors.lightLt}
      foregroundColor={colors.lightLter}
    >
      <rect x="0" y="0" rx="10" ry="10" width="240" height="240" />
      <rect x="20" y="266" rx="3" ry="3" width="160" height="6" />
      <rect x="20" y="289" rx="3" ry="3" width="120" height="6" />
      <rect x="20" y="326" rx="3" ry="3" width="50" height="6" />
      <circle cx="200" cy="330" r="20" />
    </ContentLoader>
  </div>
);

export const CatalogProductsPlaceholder = ({ cardsLength = 3 }) => (
  <Row>
    {Array(cardsLength)
      .fill(0)
      .map((v: any, i: any) => (
        <ColStyled xs={12} sm={6} md={4} lg={4} key={i}>
          <CardPlaceholder />
        </ColStyled>
      ))}
  </Row>
);
