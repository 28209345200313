import React from "react";
import posed from "react-pose";
import styled from "styled-components";
import { logoCrown } from "./assets";
import { IconBurger16, IconCross16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { LogicalLink } from "./LogicalLink";
import { ILoginAsBannerProps } from "./LoginAsBanner";
import { Wrapper } from "./Wrapper";

const Container = styled.div`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid ${colors.lightLt};
  display: flex;
  height: 65px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Posed = posed.div({
  off: {
    opacity: 0,
  },
  on: {
    opacity: 1,
  },
});

export interface ILayoutHeaderMobileProps {
  menuMobileOpen: boolean;
  onOpen: (v: boolean) => void;
  onClose: (v: boolean) => void;
  loginAs?: boolean;
  loginAsBannerProps: ILoginAsBannerProps;
}

export const LayoutHeaderMobile = ({
  menuMobileOpen,
  onOpen,
  onClose,
}: ILayoutHeaderMobileProps) => (
  <Container>
    <Wrapper>
      <Inner>
        {menuMobileOpen ? (
          <Posed key="a" pose={"on"} initialPose={"off"}>
            <div onClick={() => onClose(false)}>
              <IconCross16 fill={colors.darkDk} width={16} />
            </div>
          </Posed>
        ) : (
          <Posed key="b" pose={"on"} initialPose={"off"}>
            <div onClick={() => onOpen(true)}>
              <IconBurger16 fill={colors.darkDk} height={12} />
            </div>
          </Posed>
        )}
        <LogicalLink to="/">
          <Logo src={logoCrown} width="49" alt="Royal Canin" />
        </LogicalLink>
      </Inner>
    </Wrapper>
  </Container>
);
