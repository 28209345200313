import * as React from "react";
import styled from "styled-components";

const SvgPlus10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M60 40h30c5.5 0 10 4.5 10 10s-4.5 10-10 10H60v30c0 5.5-4.5 10-10 10s-10-4.5-10-10V60H10C4.5 60 0 55.5 0 50s4.5-10 10-10h30V10c0-5.5 4.5-10 10-10s10 4.5 10 10v30z" />
  </svg>
);

export default styled(SvgPlus10)``;
