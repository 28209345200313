import * as React from "react";
import styled from "styled-components";

const SvgArrowB16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 44" {...props}>
    <path d="M98 11.7L54.9 42c-2.7 2.7-7 2.7-9.7 0L2 11.7C-.7 9-.7 4.7 2 2 4.7-.7 9-.7 11.7 2L50 27.5 88.3 2C91-.7 95.3-.7 98 2c1.3 1.3 2 3.1 2 4.8 0 1.8-.7 3.5-2 4.9z" />
  </svg>
);

export default styled(SvgArrowB16)``;
