import React from "react";
import styled, { css } from "styled-components";
import { IconInfo16, IconRoundedCross16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled.div<{ type?: string }>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  background: #fff;
  border-radius: 10px;
  border: 1px solid ${colors.lightLt};
  color: ${colors.dark};
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  ${p =>
    p.type === "warning" &&
    css`
      color: ${colors.warning};
    `}

  ${p =>
    p.type === "error" &&
    css`
      color: ${colors.primaryDk};
      border-color: ${colors.primaryDk};
    `}
`;

const Icon = styled.div`
  flex-shrink: 0;

  svg {
    display: block;
  }
`;

export const Alert = ({
  children,
  className,
  type,
}: {
  children: React.ReactNode;
  className?: string;
  type?: string;
}) => (
  <Container type={type} className={className}>
    {type === "warning" && (
      <Icon>
        <IconInfo16 fill={colors.warning} width={16} height={16} />
      </Icon>
    )}
    {type === "error" && (
      <Icon>
        <IconRoundedCross16 fill={colors.primaryDk} width={16} height={16} />
      </Icon>
    )}
    {children}
  </Container>
);
