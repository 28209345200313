import styled, { css } from "styled-components";
import { Alert } from "./Alert";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";

export const Field = styled.div<{ borderless?: boolean }>`
  ${p =>
    p.borderless
      ? css``
      : css`
          margin-bottom: 30px;
          padding-bottom: 30px;
          border-bottom: 1px solid ${colors.lightLt};
        `};
`;

export const FieldTitle = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  ${fontSize("25px")}
  letter-spacing: -0.5px;
  margin-bottom: 40px;
  line-height: 1;

  ${mediaQuery("phablet")`
    margin-bottom: 20px;
  `}
`;

export const FieldGender = styled.div`
  display: flex;
  align-items: baseline;

  > span {
    ${fontFamily(ffdinPro.medium)}
    display: inline-block;
    flex-shrink: 0;
    letter-spacing: -0.5px;
    margin-left: 15px;
  }
`;

export const AdWarning = styled(Alert)`
  margin-bottom: 40px;
`;
