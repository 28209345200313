import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { ICatalogProduct } from "./CatalogProducts";
import { HtmlContent } from "./HtmlContent";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabsContainer = styled.div<{ name?: string }>`
  flex-grow: 1;
  margin-top: 100px;

  ${mediaQuery("tablet")`
    margin-top: 30px;
  `}
`;

const TabContent = styled.div`
  margin-top: 60px;

  ${mediaQuery("tablet")`
    margin-top: 30px;
  `}
`;

const TabCustom = styled(Tab)`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
  color: ${colors.darkDk};
`;

const TabsMenu = styled(Tabs)`
  border-bottom: 1px solid ${colors.lightLt};
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid ${colors.lightLt};
  border-spacing: 0;
  border-radius: 10px;

  td,
  th {
    padding: 30px 20px;
    text-align: left;
  }

  tr:not(:first-child) td {
    border-top: 1px solid ${colors.lightLt};
  }

  tr:not(:first-child) th {
    border-top: 1px solid ${colors.lightLt};
  }
`;

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabContent
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </TabContent>
  );
}

function a11yProps(index: any) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`,
  };
}

export const CatalogDetailTabs = ({
  product,
}: {
  product: ICatalogProduct;
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const infoTabIndex = product.longDescription ? 1 : 0
  return (
    <TabsContainer name="tabs-container">
      <TabsMenu
        centered
        value={value}
        onChange={handleChange}
        aria-label="product info tab"
        indicatorColor="primary"
        textColor="primary"

      >
        {!!product.longDescription?.trim() && (
          <TabCustom label="Description" {...a11yProps(0)} />
        )}
        <TabCustom label="Information" {...a11yProps(infoTabIndex)} />
      </TabsMenu>
      {!!product.longDescription?.trim() && (
        <TabPanel value={value} index={0}>
          <Row center="md">
            <Col xs={12} sm={12} md={8} lg={8}>
              <div style={{ whiteSpace: "pre-line" }}>
                <HtmlContent html={product.longDescription} />
              </div>
            </Col>
          </Row>
        </TabPanel>
      )}
      <TabPanel value={value} index={infoTabIndex}>
        <Row center="md">
          <Col xs={12} sm={12} md={8} lg={8}>
            <Table>
              <tbody>
                <tr>
                  <th>Dénomination</th>
                  <td>
                    <div>{product.label}</div>
                    {product.subLabel}
                  </td>
                </tr>
                <tr>
                  <th>Référence</th>
                  <td>
                    <div>{product.reference || "-"}</div>
                  </td>
                </tr>
                <tr>
                  <th>Fournisseur</th>
                  <td>
                    <div>
                      {product.wholesalers
                        .map(wholesaler => wholesaler.label)
                        .join(", ")}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Catégorie</th>
                  <td>
                    <div>
                      {product.categories
                        .map(category => category.label)
                        .join(", ")}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </TabPanel>
    </TabsContainer>
  );
};
