import React from "react";
import styled, { css } from "styled-components";
import { IconArrowR10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { LogicalLink } from "./LogicalLink";

export interface IItemBreadcrumb {
  label: string;
  link?: string;
}

const Container = styled.div`
  display: flex;
  margin-bottom: 60px;
`;

const Item = styled(LogicalLink)`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  color: ${colors.dark};
  letter-spacing: -0.5px;
  text-decoration: none;
  transition: color .2s;
  align-items: center;

  ${p =>
    p.href &&
    css`
      &:hover {
        color: ${colors.primary};
      }
    `}
`;

const Arrow = styled(IconArrowR10)`
  margin: 0 10px;
`;

export const Breadcrumb = ({
  breadcrumb,
}: {
  breadcrumb: IItemBreadcrumb[];
}) => (
  <Container>
    {breadcrumb.map((item, i) => (
      <Item href={item.link ? item.link : undefined} key={i}>
        {item.label}
        {i + 1 < breadcrumb.length && (
          <Arrow height={10} width={5} fill={colors.dark} />
        )}
      </Item>
    ))}
  </Container>
);
