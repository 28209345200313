import React from "react";
import styled from "styled-components";
import { CardIcon, ICardIconProps } from "./CardIcon";
import { IconKit40, IconPublish40 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";

const SplitContainer = styled.div`
  display: flex;

  ${mediaQuery("phablet")`
      display: block;
    `}
`;

const Split = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  align-items: center;
  color: ${colors.dark};
  display: flex;
  letter-spacing: -0.5px;
  margin: 0 30px;
  position: relative;
  text-align: center;

  ${mediaQuery("phablet")`
    margin: 0;
    justify-content: center;
    margin: 15px 0;
  `}

  &::before {
    content: "";
    width: 1px;
    top: 15px;
    bottom: 15px;
    position: absolute;
    background: ${colors.lightLt};
    z-index: -1;
    left: 50%;
    margin-left: -0.5px;

    ${mediaQuery("phablet")`
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      top: 50%;
      bottom: auto;
      margin-top: -0.5px;
    `}
  }
`;

const Text = styled.span`
  display: inline-block;
  background: #fff;
  padding: 15px 0;

  ${mediaQuery("phablet")`
    padding: 0 15px;
  `}
`;

export const LittersChoiceCards = (props: ICardIconProps["inputProps"]) => (
  <SplitContainer>
    <CardIcon
      inputProps={{
        ...props,
        name: "kit",
      }}
      icon={<IconKit40 width={40} fill={colors.darkDk} />}
    >
      Commander des kits
    </CardIcon>
    <Split>
      <Text>et/ou</Text>
    </Split>
    <CardIcon
      icon={<IconPublish40 width={40} fill={colors.darkDk} />}
      inputProps={{
        ...props,
        name: "ad",
      }}
    >
      Publier une annonce
    </CardIcon>
  </SplitContainer>
);
