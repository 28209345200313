import * as React from "react";
import styled from "styled-components";

const SvgRoundedCross16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path
      className="rounded-cross-16_svg__st0"
      d="M58.8 50l8.1-8.1c2.4-2.4 2.4-6.4 0-8.8s-6.4-2.4-8.8 0L50 41.2l-8.1-8.1c-2.4-2.4-6.4-2.4-8.8 0-2.4 2.4-2.4 6.4 0 8.8l8.1 8.1-8.1 8.1c-2.4 2.4-2.4 6.4 0 8.8 1.2 1.2 2.8 1.8 4.4 1.8 1.6 0 3.2-.6 4.4-1.8l8.1-8.1 8.1 8.1c1.2 1.2 2.8 1.8 4.4 1.8 1.6 0 3.2-.6 4.4-1.8 2.4-2.4 2.4-6.4 0-8.8L58.8 50zM50 100C22.4 100 0 77.6 0 50S22.4 0 50 0s50 22.4 50 50-22.4 50-50 50m0-87.5c-20.7 0-37.5 16.8-37.5 37.5S29.3 87.5 50 87.5 87.5 70.7 87.5 50 70.7 12.5 50 12.5"
    />
  </svg>
);

export default styled(SvgRoundedCross16)``;
