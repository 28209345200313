import { pointsToCouronnes } from "@royalcanin-fr-loyalty/common";
import React from "react";
import styled from "styled-components";
import { CatalogPurchaseType } from "../../../__generated__/globalTypes";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { ICatalogPurchase } from "./OrdersDetail";
import { ShippingDetail } from "./ShippingDetail";

const Container = styled.div`
  align-items: flex-start;
  border-top: 1px dotted ${colors.light};

  ${mediaQuery("desktop")`
    padding-top: 40px;
    margin-top: 40px;
    display: flex;
  `}

  ${mediaQuery("phablet")`
    padding-top: 20px;
    margin-top: 20px;
  `}
`;

const Product = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  flex: 1;

  ${mediaQuery("desktop")`
    margin-left: 30px;
  `}

  ${mediaQuery("tablet")`
    margin-top: 20px;
  `}
`;

const ProductImageContainer = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 80px;
  margin-right: 20px;

  ${mediaQuery("phablet")`
    display: none;
  `}
`;

const ProductContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ProductQuantity = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  color: ${colors.darkDk};
  letter-spacing: -0.5px;
  margin-right: 10px;
  white-space: nowrap;
`;

const ProductPackaging = styled.div`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-top: 5px;
`;

const ProductPointsContainer = styled.div`
  display: flex;
  margin-left: auto;
  white-space: nowrap;
`;

const ProductPoints = styled.div`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("19px")}
  color: ${colors.darkDk};
  letter-spacing: -0.5px;
`;

const ProductPointsPromo = styled.div`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("14px")}
  color: ${colors.light};
  letter-spacing: -0.5px;
  margin-right: 10px;
  text-decoration: line-through;
`;

const ShipDetail = styled.div`
  ${mediaQuery("desktop")`
    width: 32%;
  `}
`;

export const OrdersDetailProduct = ({ item }: { item: ICatalogPurchase }) => (
  <Container>
    <ShipDetail>
      <ShippingDetail
        name={item.partner.name}
        {...item.shippingAddress}
        showImmediateDeliveryMessage
        isImmediateDelivery={
          item.shippingAddress.isImmediateDelivery ||
          item.type === CatalogPurchaseType.premia
        }
      />
    </ShipDetail>
    <Product>
      <ProductImageContainer
        style={
          item.productData.image
            ? {
                backgroundImage: `url(${item.productData.image})`,
              }
            : {}
        }
      />
      <ProductContainer>
        <div>
          <ProductQuantity>{item.quantity} x</ProductQuantity>
        </div>
        <div style={{ marginRight: 20 }}>
          <div>{item.productData.name}</div>
          {item.productData.subLabel && (
            <ProductPackaging>{item.productData.subLabel}</ProductPackaging>
          )}
        </div>
        <ProductPointsContainer>
          {item.initialPrice !== item.price && (
            <ProductPointsPromo>
              {pointsToCouronnes(item.initialPrice)} couronnes
            </ProductPointsPromo>
          )}
          <ProductPoints>
            {item.price > 0 && <>{pointsToCouronnes(item.price)} couronnes</>}
          </ProductPoints>
        </ProductPointsContainer>
      </ProductContainer>
    </Product>
  </Container>
);
