import React from "react";
import ContentLoader from "react-content-loader";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { colors } from "./lib/constants";

const ColStyled = styled(Col)`
  display: flex !important;
  flex-direction: column;
`;

const ImagePlaceholder = () => (
  <div style={{ width: "100%" }}>
    <ContentLoader
      style={{ width: "100%" }}
      height={330}
      width={330}
      backgroundColor={colors.lightLt}
      foregroundColor={colors.lightLter}
    >
      <rect x="0" y="0" rx="10" ry="10" width="330" height="330" />
    </ContentLoader>
  </div>
);

const SheetPlaceholder = () => (
  <div style={{ width: "100%" }}>
    <ContentLoader
      style={{ width: "100%" }}
      width={600}
      height={330}
      backgroundColor={colors.lightLt}
      foregroundColor={colors.lightLter}
    >
      <rect x="0" y="0" rx="3" ry="3" width="600" height="20" />
      <rect x="0" y="30" rx="3" ry="3" width="50" height="10" />
      <rect x="0" y="90" rx="3" ry="3" width="150" height="10" />
      <rect x="0" y="190" rx="3" ry="3" width="600" height="80" />
    </ContentLoader>
  </div>
);

export const CatalogDetailSheetPlaceholder = () => (
  <Row middle="xs">
    <Col xs={12} sm={12} md={4} lg={4}>
      <ImagePlaceholder />
    </Col>
    <ColStyled xs={12} sm={12} mdOffset={1} md={7} lgOffset={1} lg={7}>
      <SheetPlaceholder />
    </ColStyled>
  </Row>
);
