import React from "react";
import styled from "styled-components";
import { logoCrown, logoRoyalcanin } from "./assets";
import { colors } from "./lib/constants";
import { mediaQuery } from "./lib/media";
import { LogicalLink } from "./LogicalLink";

const Container = styled.div`
  align-items: center;
  display: flex;

  ${mediaQuery("desktop")`
     margin-right: 30px;
  `}

  ${mediaQuery("tablet")`
     justify-content: center;
  `}

  img {
    display: block;
  }
`;

const LogoPartner = styled.div`
  border-left: 1px solid ${colors.lightLt};
  margin-left: 30px;
  padding-left: 30px;

  img {
    max-width: 150px;
    height: auto;
    width: 100%;
  }
`;

const LogoRoyalCanin = styled(LogicalLink)`
  display: block;

  img {
    display: block;
  }
`;

export const Logos = ({
  logo,
  href,
  logoLink,
}: {
  logo?: string;
  href: string;
  logoLink?: string;
}) => (
  <>
    {logo ? (
      <>
        <Container>
          <LogoRoyalCanin to={href}>
            <img src={logoCrown} width="49" alt="Royal Canin" />
          </LogoRoyalCanin>
          <LogoPartner>
            {logoLink ? (
              <LogicalLink to={logoLink} target="_blank">
                <img src={logo} height="40" />
              </LogicalLink>
            ) : (
              <img src={logo} height="40" />
            )}
          </LogoPartner>
        </Container>
      </>
    ) : (
      <LogoRoyalCanin to={href}>
        <img src={logoRoyalcanin} width="138" alt="Royal Canin" />
      </LogoRoyalCanin>
    )}
  </>
);
