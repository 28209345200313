import { Radio as MUIRadio } from "@material-ui/core";
import { RadioProps } from "@material-ui/core/Radio";
import { omit } from "lodash";
import React from "react";
import styled from "styled-components";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

interface ICheckboxListItem {
  value?: any;
  label: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;

  > div:not(:first-child) {
    margin-left: 21px;
  }
`;

const Label = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
`;

export type IRadioProps = RadioProps;

export const RadioGroup = ({
  children,
  items,
  value,
  ...props
}: {
  items: ICheckboxListItem[];
} & Omit<RadioProps, "type" | "onChange"> & {
    type?: any;
    onChange: (value: any) => void;
    children?: React.ReactNode | React.ReactChild;
  }) => {
  const radioProps = omit(props, "helperText", "error");
  return (
    <Container>
      <Label>{children}</Label>
      <RadioContainer>
        {items.map((item, i) => (
          <div key={i}>
            <MUIRadio
              {...radioProps}
              checked={value === item.value}
              value={item.value}
              onChange={() => {
                radioProps.onChange(item.value);
              }}
            />
            <span>{item.label}</span>
          </div>
        ))}
      </RadioContainer>
    </Container>
  );
};
