import * as React from "react";
import styled from "styled-components";

const SvgArrowB10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 50" {...props}>
    <path
      className="arrow-b-10_svg__st0"
      d="M96.8 18.6c2.1-2.1 3.2-4.9 3.2-7.7s-1.1-5.6-3.2-7.7C92.5-1 85.6-1 81.3 3.2L50 23.7 18.7 3.2C14.4-1 7.5-1 3.2 3.2c-4.3 4.3-4.3 11.1 0 15.4l39 28.2c4.3 4.3 11.2 4.3 15.5 0l39.1-28.2z"
    />
  </svg>
);

export default styled(SvgArrowB10)``;
