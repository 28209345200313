import * as React from "react";
import styled from "styled-components";

const SvgRoundedCheck20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M72.9 30.9c2.2 1.6 2.8 4.8 1.2 7L55.9 63.7c-.5.7-1 1.3-1.6 1.9-5.9 5.9-15.4 5.9-21.2 0l-6.7-6.7c-2-2-2-5.2 0-7.1 2-2 5.1-2 7.1 0l6.7 6.7c2 2 5.1 2 7.1 0 .2-.2.4-.4.5-.6l18.1-25.7c1.6-2.4 4.8-2.9 7-1.3zM50 10c-22.1 0-40 17.9-40 40s17.9 40 40 40 40-17.9 40-40-17.9-40-40-40zm0-10c27.6 0 50 22.4 50 50s-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0z" />
  </svg>
);

export default styled(SvgRoundedCheck20)``;
