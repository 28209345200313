import { Modal as MUIModal } from "@material-ui/core";
import clamp from "lodash/clamp";
import React, { useEffect, useRef } from "react";
import { animated, config, useSpring } from "react-spring";
import { useDrag } from "react-use-gesture";
import styled, { CSSProperties } from "styled-components";
import { IconClose80 } from "./icons-ts";
import { colors } from "./lib/constants";
import { Media } from "./lib/media";

const Backdrop = styled(
  ({
    className,
    style,
    onClick,
  }: {
    className?: string;
    style: CSSProperties;
    onClick: () => void;
  }) => <animated.div className={className} onClick={onClick} style={style} />,
)`
  background-color: rgba(51, 51, 51, 0.75);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  touch-action: none;
  justify-content: center;
  -webkit-tap-highlight-color: #0000;
`;

const ModalStyled = styled(MUIModal)`
  display: flex;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  margin-top: 66px;
  position: relative;
`;

const AnimatedCard = animated(Card);

const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 30px;
  padding-top: 15px;
`;

const CloseMobile = styled(IconClose80)`
  display: block;
`;

const Content = styled.div`
  overflow-y: auto;
  padding: 0 15px 15px 15px;
`;

type CustomCSSStyleDeclaration = CSSStyleDeclaration & {
  overscrollBehavior?: "auto" | "contain" | "none";
};

export const Swipper = ({
  open: isOpen,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const draggingRef = useRef(false);
  const [{ y }, set] = useSpring(() => ({ y: 1 }));
  const windowHeight = typeof window === "undefined" ? 0 : window.innerHeight;

  // When we open the panel, we disable pull to refresh
  useEffect(() => {
    const style = document.body.style as CustomCSSStyleDeclaration;
    const previous = style.overscrollBehavior;

    style.overscrollBehavior = isOpen ? "none" : previous;

    return () => {
      style.overscrollBehavior = previous;
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      set({ y: 0, config: { mass: 1, tension: 220, friction: 26 } });
    }
  }, [isOpen]);

  const open = ({ canceled }: { canceled?: boolean | undefined } = {}) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    set({ y: 0, config: canceled ? config.default : config.default });
  };
  const close = () => {
    set({ y: 1, config: config.default });
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const bind = useDrag(
    ({
      first,
      last,
      vxvy: [, vy],
      movement: [, my],
      memo = y.getValue(),
      cancel,
      canceled,
    }) => {
      const newY = memo + my;
      let newValue = newY === 0 ? 0 : newY / windowHeight;

      if (first) {
        draggingRef.current = false;
      }
      // if this is not the first or last frame, it's a moving frame
      // then it means the user is dragging
      else if (!!last) {
        draggingRef.current = true;
      }
      // adds friction when dragging the sheet upward
      // the more the user drags up, the more friction
      if (newValue < 0) {
        newValue = newValue / (1 - newValue * 0.005);
      }

      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (newValue < -120 && cancel) {
        cancel();
      }

      // when the user releases the sheet, we check whether it passed
      // the treshold for it to close, or if we reset it to its open positino
      if (last) {
        newValue > 0.5 || vy > 0.5 ? close() : open({ canceled });
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else {
        set({ y: clamp(newValue, 0, 1), config: config.stiff });
      }
      return memo;
    },
  );

  return (
    <ModalStyled
      open={isOpen}
      BackdropComponent={() => (
        <Backdrop
          onClick={() => close()}
          {...bind()}
          style={{ opacity: y.interpolate(v => -1 * v + 1) }}
        />
      )}
      onClose={onClose}
    >
      <>
        <AnimatedCard
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            transform:
              (isOpen &&
                y.interpolate(v => `translate3d(0px, ${v * 100}vh, 0px)`)) ||
              undefined,
          }}
        >
          <Media when={"<=768"}>
            <CloseContainer {...bind()}>
              <CloseMobile fill={colors.lightLt} width={80} height={5} />
            </CloseContainer>
          </Media>
          <Content>{children}</Content>
        </AnimatedCard>
      </>
    </ModalStyled>
  );
};
