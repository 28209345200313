import * as React from "react";
import styled from "styled-components";

const SvgCheck10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 80" {...props}>
    <path d="M2.9 57.7c-3.9-4-3.9-10.4 0-14.3s10.2-3.9 14.1 0l13.3 13.4c3.9 3.9 10.2 3.9 14.1 0 .4-.4.7-.8 1.1-1.3L81.9 4.2c3.2-4.5 9.5-5.6 13.9-2.3 4.5 3.2 5.5 9.6 2.3 14.1L61.9 67.3c-1 1.4-2 2.6-3.2 3.8C46.9 83 28 83 16.2 71.1L2.9 57.7z" />
  </svg>
);

export default styled(SvgCheck10)``;
