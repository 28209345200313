import React from "react";
import styled from "styled-components";
import { ILayoutNavigationProps, LayoutNavigation } from "./LayoutNavigation";
import { colors } from "./lib/constants";
import { Logos } from "./Logos";
import { Wrapper } from "./Wrapper";

const Container = styled.div`
  border-bottom: 1px solid ${colors.lightLt};
  height: 120px;
  display: flex;
`;

const Inner = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3;
  opacity: ${(p) => (p.visible ? "1" : "0")};
  flex: 1;
  position: relative;
  align-items: flex-end;
  padding-bottom: 30px;
`;

export interface ILayoutHeaderProps {
  logoMainHref: string;
  layoutNavigationProps: ILayoutNavigationProps;
  logo?: string;
  hideHeader?: boolean;
  logoLink?: string;
}

export const LayoutHeader = ({
  logoMainHref,
  layoutNavigationProps,
  logo,
  hideHeader,
  logoLink,
}: ILayoutHeaderProps) => (
  <Container>
    <Wrapper style={{ display: "flex" }}>
      <Inner visible={!hideHeader}>
        <Logos logo={logo} href={logoMainHref} logoLink={logoLink} />
        <LayoutNavigation {...layoutNavigationProps} />
      </Inner>
    </Wrapper>
  </Container>
);
