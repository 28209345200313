import * as React from "react";
import styled from "styled-components";

const SvgRoundedCheck50 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Layer 1" viewBox="0 0 100 100" {...props}>
    <path d="M36.59 55.52a2 2 0 010-2.84 2 2 0 012.83 0l4.69 4.72a2 2 0 003-.24l13.27-18.33a2 2 0 012.79-.44 2 2 0 01.44 2.81L50.37 59.51a6 6 0 01-9.1.72zM50 96A46 46 0 104 50a46 46 0 0046 46zm0 4a50 50 0 1150-50 50 50 0 01-50 50z" />
  </svg>
);

export default styled(SvgRoundedCheck50)``;
