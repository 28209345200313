import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import posed from "react-pose";
import styled, { css } from "styled-components";
import { Alert } from "./Alert";
import { Button } from "./Button";
import { IconCross10, IconPicture16, IconUpload16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { fontSize } from "./lib/fonts";
import { Media } from "./lib/media";
import { Link } from "./Link";

const Zone = styled.div`
  ${fontSize("14px")}
  align-items: center;
  background: ${colors.lightLter};
  border-radius: 10px;
  border: 1px solid ${colors.lightLt};
  box-shadow: inset 0 0 0 3px #fff;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  transition: border-color 0.2s;

  &:hover {
    border-color: ${colors.dark};
  }

  svg {
    display: block;
    margin-right: 15px;
  }

  > span {
    margin-left: 5px;
  }
`;

const AlertRemoveButton = styled(Button)``;

const AlertRemoveContainer = posed.div({
  off: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    height: "auto",
    opacity: 1,
  },
});

const AlertRemove = styled(Alert)`
  align-items: center;
  margin-top: 20px;

  ${AlertRemoveButton} {
    margin-left: auto;
  }
`;

export interface IUploadZoneFile {
  id: string;
  file?: File;
  preview?: string;
}

export const UploadZone = ({
  files,
  setFiles,
  onDelete,
  maxFiles = 7,
  maxSize = 10485760,
  accept = "image/*",
}: {
  files: IUploadZoneFile[];
  setFiles: (files: IUploadZoneFile[]) => void;
  onDelete: (file: IUploadZoneFile) => void;
  maxSize?: number;
  maxFiles?: number;
  accept?: string;
}) => {
  const [fileToDelete, setFileToDelete] = useState<
    undefined | IUploadZoneFile
  >();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles,
    isDragReject,
  } = useDropzone({
    accept,
    maxSize,
    minSize: 0,
    onDrop: acceptedFiles => {
      setFiles(
        [
          ...files,
          ...acceptedFiles.map(file => ({
            id: nanoid(),
            file,
          })),
        ].slice(0, maxFiles),
      );
    },
  });

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  const finalFiles = [
    ...files,
    ...Array(Math.max(0, maxFiles - files.length)).fill({}),
  ];

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Zone>
          {!isDragActive && (
            <>
              <IconUpload16 width={16} fill={colors.darkDk} />
              <Media when={">768"}>
                <span>Glissez vos photos ici ou </span>
              </Media>
              <Link dark>choisissez un fichier</Link>
            </>
          )}
          {isDragActive && !isDragReject && (
            <>
              <IconUpload16 width={16} fill={colors.darkDk} />
              Déposer vos fichiers ici
            </>
          )}
          {isDragReject && (
            <div style={{ color: colors.warning }}>
              Type de fichier non reconnu, uniquement .jpg ou .png
            </div>
          )}
          {isFileTooLarge && (
            <div
              style={{
                color: colors.warning,
                flexBasis: "100%",
                marginTop: 10,
              }}
            >
              Fichier trop lourd, max 10mb
            </div>
          )}
        </Zone>
      </div>
      <UploadZoneThumbnailsContainer>
        {finalFiles.map((file, i) => (
          <UploadZoneThumbnailWithFilePreview
            key={file.id || `id_${i}`}
            file={file}
            onDelete={() => setFileToDelete(file)}
          />
        ))}
      </UploadZoneThumbnailsContainer>
      <AlertRemoveContainer pose={fileToDelete ? "on" : "off"}>
        <AlertRemove type="error">
          Êtes-vous sûr de vouloir supprimer cette photo ?
          <AlertRemoveButton
            mini
            onClick={() => {
              if (fileToDelete) {
                onDelete(fileToDelete);
                setFileToDelete(undefined);
              }
            }}
          >
            Confirmer
          </AlertRemoveButton>
        </AlertRemove>
      </AlertRemoveContainer>
    </>
  );
};

const ThumbDeleteContainer = styled.div`
  align-items: center;
  background: ${colors.primaryDk};
  border-radius: 13px;
  bottom: 10px;
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: center;
  position: absolute;
  right: 10px;
  transition: transform 0.2s;
  width: 26px;

  &:hover {
    transform: translateY(-2px);
  }
`;

const UploadZoneThumbnailWithFilePreview = ({
  file,
  maxSizeForPreview = 5 * 1024 * 1024,
  onDelete,
}: {
  file: IUploadZoneFile;
  maxSizeForPreview?: number;
} & Omit<IUploadZoneThumbnailProps, "image">) => {
  const [preview, setPreview] = useState<string | undefined>();

  useEffect(() => {
    if (
      !preview &&
      !file.preview &&
      file.file &&
      file.file.size <= maxSizeForPreview
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = e => {
        setPreview(reader.result as string);
      };
    }
  }, [file.id]);

  return (
    <UploadZoneThumbnail image={file.preview || preview} onDelete={onDelete} />
  );
};

interface IUploadZoneThumbnailProps {
  image?: string;
  onDelete: () => void;
  className?: string;
}

const UploadZoneThumbnail = styled(
  ({ image, className, onDelete }: IUploadZoneThumbnailProps) => (
    <div className={className}>
      {!image ? (
        <>
          <IconPicture16 width={16} fill={colors.light} />
        </>
      ) : (
        <>
          <div
            className="content"
            style={{ backgroundImage: `url(${image})` }}
          />
          <ThumbDeleteContainer onClick={onDelete}>
            <IconCross10 fill="#fff" width={10} />
          </ThumbDeleteContainer>
        </>
      )}
    </div>
  ),
)`
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  width: calc(100% / 7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;

  &:not(:first-child) {
    margin-left: 10px;
  }

  ${p =>
    !p.image &&
    css`
      border: 1px solid ${colors.lightLt};
    `}

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
    transition: background-image 0.2s;
  }
`;

const UploadZoneThumbnailsContainer = styled.div`
  display: flex;
`;
