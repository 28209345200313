import * as React from "react";
import styled from "styled-components";

const SvgArrowL16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 44 100" {...props}>
    <path d="M32.3 2L2 45.1c-2.7 2.7-2.7 7 0 9.7L32.3 98c2.7 2.7 7 2.7 9.7 0 2.7-2.7 2.7-7 0-9.7L16.5 50 42 11.7c2.7-2.7 2.7-7 0-9.7-1.3-1.3-3.1-2-4.8-2-1.8 0-3.5.7-4.9 2z" />
  </svg>
);

export default styled(SvgArrowL16)``;
