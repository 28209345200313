import * as React from "react";
import styled from "styled-components";

const SvgRoundedCross50 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M53 50l8.4 8.4c.8.8.8 2.1 0 3-.8.8-2.1.8-3 0L50 53l-8.4 8.4c-.8.8-2.1.8-3 0-.8-.8-.8-2.1 0-3L47 50l-8.4-8.4c-.8-.8-.8-2.1 0-3 .8-.8 2.1-.8 3 0L50 47l8.4-8.4c.8-.8 2.1-.8 3 0 .8.8.8 2.1 0 3L53 50zm-3 46c25.4 0 46-20.6 46-46S75.4 4 50 4 4 24.6 4 50s20.6 46 46 46zm0 4C22.4 100 0 77.6 0 50S22.4 0 50 0s50 22.4 50 50-22.4 50-50 50z" />
  </svg>
);

export default styled(SvgRoundedCross50)``;
