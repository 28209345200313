import * as React from "react";
import styled from "styled-components";

const SvgBurger16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Layer 1" viewBox="0 0 100 75" {...props}>
    <path d="M62.5 0a6.25 6.25 0 010 12.5H6.25a6.25 6.25 0 010-12.5zm31.25 31.25a6.25 6.25 0 010 12.5H6.25a6.25 6.25 0 010-12.5zM81.25 62.5a6.25 6.25 0 010 12.5h-75a6.25 6.25 0 010-12.5z" />
  </svg>
);

export default styled(SvgBurger16)``;
