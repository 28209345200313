import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
  text-align: center;
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

export const Loader = () => (
  <LoaderContainer>
    <CircularProgress color="primary" />
  </LoaderContainer>
);
