/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CatalogPurchaseType {
  litterKit = "litterKit",
  object = "object",
  petfood = "petfood",
  premia = "premia",
}

export enum Locale {
  fr = "fr",
}

export enum ProductType {
  object = "object",
  petfood = "petfood",
  premia = "premia",
}

export interface FilterInput {
  order?: string | null;
  direction?: string | null;
  petfood?: boolean | null;
  filters?: string[] | null;
  categories?: string[] | null;
  startPoint?: number | null;
  endPoint?: number | null;
  search?: string | null;
  promo?: boolean | null;
  newProduct?: boolean | null;
}

export interface LitterRequestImageInput {
  url?: string | null;
  index?: number | null;
}

export interface LittersCreateInput {
  breedId: string;
  dob: any;
  petType: string;
  males: number;
  females: number;
}

export interface LittersUpdateAdInput {
  publicationDateEnd: any;
  shortDesc: string;
  images: LitterRequestImageInput[];
  malesAvailable: number;
  femalesAvailable: number;
  motherId?: string | null;
  fatherId?: string | null;
  isPureBred: boolean;
  pureBredLitterId?: string | null;
  price: number;
  isSterilized: boolean;
  isVaccinated: boolean;
  isChippedOrTattooed: boolean;
  breedId: string;
  royalStartSyncAvailable: boolean;
  weeksAvailability: number;
}

export interface LittersUpdateKitInput {
  litterKit: string;
  quantity: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
