import { UserProvider } from "@igloo-be-omnipartners/hooks";
import { UIKitProvider } from "@royalcanin-fr-loyalty/ui-kit";
import { SnackbarProvider } from "notistack";
import React from "react";
import { GraphQLProvider } from "./GraphQLProvider";

export const MainProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <UIKitProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        maxSnack={10}
        preventDuplicate
        autoHideDuration={6000}
      >
        <GraphQLProvider>
          <UserProvider>
            <>{children}</>
          </UserProvider>
        </GraphQLProvider>
      </SnackbarProvider>
    </UIKitProvider>
  );
};
