import React from "react";
import styled from "styled-components";

// Grid
import { Grid } from "react-styled-flexboxgrid";

export const Wrapper = styled(props => <Grid fluid {...props} />)`
  margin: 0 auto;
  max-width: 1050px;
  flex: 1 0 0;
`;
