import * as React from "react";
import styled from "styled-components";

const SvgCross16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M59.2 50l38.9 38.9c2.6 2.6 2.6 6.7 0 9.2-2.6 2.6-6.7 2.6-9.2 0L50 59.2 11.1 98.1c-2.6 2.6-6.7 2.6-9.2 0s-2.6-6.7 0-9.2L40.8 50 1.9 11.1c-2.6-2.6-2.6-6.7 0-9.2s6.7-2.6 9.2 0L50 40.8 88.9 1.9c2.6-2.6 6.7-2.6 9.2 0 2.6 2.6 2.6 6.7 0 9.2L59.2 50z" />
  </svg>
);

export default styled(SvgCross16)``;
