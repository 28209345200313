import * as React from "react";
import styled from "styled-components";

const SvgPublish40 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M97.1 37.9c3.9 3.9 3.9 10.2 0 14L64 85H50V71l33.1-33.1c3.8-3.9 10.1-3.9 14 0zm-7.9 14.8l-7-7L55 73v7h7l27.2-27.3zm3.5-3.5l.8-.8c1.9-1.9 1.9-5 0-7-1.9-1.9-5-1.9-7 0l-.8.8 7 7zM20 45h35c1.4 0 2.5 1.1 2.5 2.5S56.4 50 55 50H20c-1.4 0-2.5-1.1-2.5-2.5S18.6 45 20 45zm0-10h35c1.4 0 2.5 1.1 2.5 2.5S56.4 40 55 40H20c-1.4 0-2.5-1.1-2.5-2.5S18.6 35 20 35zm0-10h35c1.4 0 2.5 1.1 2.5 2.5S56.4 30 55 30H20c-1.4 0-2.5-1.1-2.5-2.5S18.6 25 20 25zm52.5 15c-1.4 0-2.5-1.1-2.5-2.5v-25C70 8.4 66.6 5 62.5 5h-50C8.4 5 5 8.4 5 12.5v75c0 4.1 3.4 7.5 7.5 7.5h50c4.1 0 7.5-3.4 7.5-7.5 0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5c0 6.9-5.6 12.5-12.5 12.5h-50C5.6 100 0 94.4 0 87.5v-75C0 5.6 5.6 0 12.5 0h50C69.4 0 75 5.6 75 12.5v25c0 1.4-1.1 2.5-2.5 2.5z" />
  </svg>
);

export default styled(SvgPublish40)``;
