import * as React from "react";
import styled from "styled-components";

const SvgRefresh20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} {...props}>
    <path d="M16.782 6c-1.568-2.733-4.69-4.444-8.146-3.9-3.465.546-6.217 3.472-6.603 6.987C1.503 13.914 5.235 18 9.913 18c3.056 0 5.71-1.745 7.033-4.3a.984.984 0 011.386-.404c.455.276.613.865.367 1.34C17.042 17.825 13.729 20 9.912 20 4.066 20-.598 14.894.062 8.862c.49-4.47 4.024-8.15 8.44-8.764 3.908-.543 7.454 1.214 9.499 4.12V3a1 1 0 012 0v4a1 1 0 01-.884.993L19 8h-4a1 1 0 010-2h1.782z" />
  </svg>
);

export default styled(SvgRefresh20)``;
