import * as React from "react";
import styled from "styled-components";

const SvgCalendar16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M81.2 87.5H18.8c-3.5 0-6.2-2.8-6.2-6.2V50h75v31.2c-.1 3.5-2.9 6.3-6.4 6.3zM18.8 25h62.5c3.5 0 6.2 2.8 6.2 6.2v6.2h-75v-6.2c0-3.4 2.8-6.2 6.3-6.2zm62.4-12.5V6.2C81.2 2.8 78.5 0 75 0s-6.2 2.8-6.2 6.2v6.2H31.2V6.2C31.2 2.8 28.5 0 25 0s-6.2 2.8-6.2 6.2v6.2C8.4 12.5 0 20.9 0 31.2v50C0 91.6 8.4 100 18.8 100h62.5c10.4 0 18.8-8.4 18.8-18.8v-50c-.1-10.3-8.5-18.7-18.9-18.7z" />
  </svg>
);

export default styled(SvgCalendar16)``;
