import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { IconArrowL10, IconCheck10, IconRoundedCross16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { LogicalLink } from "./LogicalLink";

const Container = styled.div`
  align-items: center;
  border-top: 1px solid ${colors.lightLt};
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  justify-content: flex-end;

  ${mediaQuery("phablet")`
    justify-content: center;
  `}
`;

const Error = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  align-items: center;
  color: ${colors.primaryDk};
  display: flex;
  letter-spacing: -0.5px;
  margin-right: auto;
  padding-right: 10px;

  ${IconRoundedCross16} {
    margin-right: 10px;
    flex-shrink: 0;
  }
`;

const LinkNewOrder = styled(LogicalLink)`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  color: ${colors.primary};
  transition: color .2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: auto;

  &:hover {
    color: ${colors.primaryDk};

    ${IconArrowL10} { fill: ${colors.primaryDk}; }
  }

  ${IconArrowL10} {
    margin-right: 15px;
    transition: fill .2s;
  }
`;

export const ModalOrderSubmit = ({
  catalogLink,
  ordersLink,
  orderButtonDisabled,
  error,
  success,
}: {
  catalogLink?: string;
  ordersLink?: string;
  orderButtonDisabled?: boolean;
  error?: boolean;
  success?: boolean;
}) => (
  <Container>
    {error && (
      <>
        <Error>
          <IconRoundedCross16 width={16} fill={colors.primaryDk} />
          Solde de couronnes insuffisant pour cette quantité.
        </Error>
      </>
    )}
    {success ? (
      <>
        <LinkNewOrder href={catalogLink}>
          <IconArrowL10 height={10} fill={colors.primary} /> Nouvelle commande
        </LinkNewOrder>
        <Button href={ordersLink}>Mes commandes</Button>
      </>
    ) : (
      <Button
        disabled={error || orderButtonDisabled}
        type="submit"
        prefix={<IconCheck10 width={10} fill="#fff" />}
      >
        Commander
      </Button>
    )}
  </Container>
);
