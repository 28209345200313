import React from "react";
import styled, { css } from "styled-components";
import { Logos } from "./Logos";
import { NewUserLink } from "./NewUserLink";

const Container = styled.div<{ hasLogo: boolean }>`
  display: flex;
  padding-top: 4px;
  justify-content: center;

  ${(p) =>
    p.hasLogo &&
    css`
      align-items: center;
      justify-content: space-between;
    `}
`;

export interface ILoginHeaderProps {
  logoMainHref: string;
  logo?: string;
  newUserLink?: string;
  logoLink?: string;
}

export const LoginHeader = ({
  logoMainHref,
  logo,
  newUserLink,
  logoLink,
}: ILoginHeaderProps) => (
  <>
    <Container hasLogo={!!logo}>
      <Logos logo={logo} href={logoMainHref} logoLink={logoLink} />
      {newUserLink && <NewUserLink href={newUserLink} target="_blank" />}
    </Container>
  </>
);
