import ArrowB10 from "./ArrowB10";
export const IconArrowB10 = ArrowB10;

import ArrowB16 from "./ArrowB16";
export const IconArrowB16 = ArrowB16;

import ArrowL10 from "./ArrowL10";
export const IconArrowL10 = ArrowL10;

import ArrowL16 from "./ArrowL16";
export const IconArrowL16 = ArrowL16;

import ArrowR10 from "./ArrowR10";
export const IconArrowR10 = ArrowR10;

import ArrowR16 from "./ArrowR16";
export const IconArrowR16 = ArrowR16;

import ArrowT10 from "./ArrowT10";
export const IconArrowT10 = ArrowT10;

import ArrowT16 from "./ArrowT16";
export const IconArrowT16 = ArrowT16;

import Basket16 from "./Basket16";
export const IconBasket16 = Basket16;

import Box20 from "./Box20";
export const IconBox20 = Box20;

import Burger16 from "./Burger16";
export const IconBurger16 = Burger16;

import Calendar16 from "./Calendar16";
export const IconCalendar16 = Calendar16;

import Check10 from "./Check10";
export const IconCheck10 = Check10;

import Close80 from "./Close80";
export const IconClose80 = Close80;

import Cross10 from "./Cross10";
export const IconCross10 = Cross10;

import Cross16 from "./Cross16";
export const IconCross16 = Cross16;

import Euro16 from "./Euro16";
export const IconEuro16 = Euro16;

import EyeClose16 from "./EyeClose16";
export const IconEyeClose16 = EyeClose16;

import EyeOpen16 from "./EyeOpen16";
export const IconEyeOpen16 = EyeOpen16;

import Female16 from "./Female16";
export const IconFemale16 = Female16;

import Info16 from "./Info16";
export const IconInfo16 = Info16;

import Kit40 from "./Kit40";
export const IconKit40 = Kit40;

import Male16 from "./Male16";
export const IconMale16 = Male16;

import Minus10 from "./Minus10";
export const IconMinus10 = Minus10;

import Padlock16 from "./Padlock16";
export const IconPadlock16 = Padlock16;

import Picture16 from "./Picture16";
export const IconPicture16 = Picture16;

import Plus10 from "./Plus10";
export const IconPlus10 = Plus10;

import Publish40 from "./Publish40";
export const IconPublish40 = Publish40;

import Question16 from "./Question16";
export const IconQuestion16 = Question16;

import Redirect14 from "./Redirect14";
export const IconRedirect14 = Redirect14;

import Refresh20 from "./Refresh20";
export const IconRefresh20 = Refresh20;

import RoundedCheck16 from "./RoundedCheck16";
export const IconRoundedCheck16 = RoundedCheck16;

import RoundedCheck20 from "./RoundedCheck20";
export const IconRoundedCheck20 = RoundedCheck20;

import RoundedCheck50 from "./RoundedCheck50";
export const IconRoundedCheck50 = RoundedCheck50;

import RoundedCross16 from "./RoundedCross16";
export const IconRoundedCross16 = RoundedCross16;

import RoundedCross20 from "./RoundedCross20";
export const IconRoundedCross20 = RoundedCross20;

import RoundedCross50 from "./RoundedCross50";
export const IconRoundedCross50 = RoundedCross50;

import RoundedMinus30 from "./RoundedMinus30";
export const IconRoundedMinus30 = RoundedMinus30;

import RoundedPlus30 from "./RoundedPlus30";
export const IconRoundedPlus30 = RoundedPlus30;

import Truck20 from "./Truck20";
export const IconTruck20 = Truck20;

import Upload16 from "./Upload16";
export const IconUpload16 = Upload16;

import User16 from "./User16";
export const IconUser16 = User16;
