import React from "react";
import styled from "styled-components";
import { ICatalogProduct } from "./CatalogProducts";
import { InputNumber } from "./InputNumber";
import { colors } from "./lib/constants";
import { fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { Tag } from "./Tag";

const ProductContainer = styled.div`
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery("phablet")`
    display: block;
  `}
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery("phablet")`
    flex-direction: column;
    text-align: center;
  `}
`;

const InputContainer = styled.div`
  ${mediaQuery("phablet")`
    text-align: center;
    margin-top: 30px;
  `}
`;

const ProductImage = styled.div<{ background: string[] }>`
  background-image: url(${p => p.background[0]});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 70px;
  width: 70px;

  ${mediaQuery("desktop")`
    margin-right: 20px;
  `}

  ${mediaQuery("phablet")`
    margin-bottom: 20px;
    height: 100px;
    width: 100px;
  `}
`;

const ProductLabel = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery("phablet")`
    ${fontSize("19px")}
  `}
`;

const TagContainer = styled.div`
  margin-left: 10px;
`;

const ProductPackaging = styled.div`
  letter-spacing: -0.5px;
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-top: 5px;
`;

const Separator = styled.div`
  background-image: linear-gradient(180deg, #f5f5f5 0%, #fefefe 100%);
  bottom: 0;
  height: 22px;
  left: -40px;
  position: absolute;
  right: -40px;

  ${mediaQuery("phablet")`
    left: -15px;
    right: -15px;
  `}
`;

const Container = styled.div`
  position: relative;
`;

export const ModalOrderProduct = ({
  product,
  quantityInputProps,
}: {
  product: ICatalogProduct;
  quantityInputProps: {
    value: number;
    onChange: (data: number) => void;
  };
}) => (
  <Container>
    <ProductContainer>
      <ProductInfo>
        {product.images && (
          <ProductImage background={product.images.map(i => i.src)} />
        )}
        <div>
          <ProductLabel>
            {product.label}
            {product.promo && (
              <TagContainer>
                <Tag theme="success">Promo</Tag>
              </TagContainer>
            )}
          </ProductLabel>
          {product.subLabel && (
            <ProductPackaging>
              {product.subLabel}
            </ProductPackaging>
          )}
        </div>
      </ProductInfo>
      <InputContainer>
        <InputNumber min={1} max={999} step={1} {...quantityInputProps} />
      </InputContainer>
    </ProductContainer>
    <Separator />
  </Container>
);
