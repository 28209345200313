import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { mediaQuery } from "./lib/media";
import { ILoginHeaderProps, LoginHeader } from "./LoginHeader";
import { Wrapper } from "./Wrapper";

const WrapperFlex = styled(Wrapper)`
  ${mediaQuery("tablet")`
    flex: 1;
    display: flex;
    flex-direction: column;
  `}
`;

const Form = styled(Row)`
  ${mediaQuery("tablet")`
    flex: 1;
  `}
`;

export interface ILoginProps {
  loginHeaderProps: ILoginHeaderProps;
  children: React.ReactNode;
}

export const Login = ({ loginHeaderProps, children }: ILoginProps) => (
  <WrapperFlex>
    <Row center="xs">
      <Col xs={12} sm={9} md={10} lg={8}>
        <LoginHeader {...loginHeaderProps} />
      </Col>
    </Row>
    <Form center="xs">
      <Col xs={12} sm={8} md={6} lg={4}>
        {children}
      </Col>
    </Form>
  </WrapperFlex>
);
