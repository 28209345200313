import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import { GlobalStyles } from "./GlobalStyles";
import { ILayoutFooterProps, LayoutFooter } from "./LayoutFooter";
import { mediaQuery } from "./lib/media";

const Content = styled.div`
  flex: 1;
  padding-bottom: 100px;
  padding-top: 60px;

  ${mediaQuery("tablet")`
   display: flex;
    padding: 60px 0;
  `}

  ${mediaQuery("phablet")`
    display: flex;
    padding: 30px 0;
  `}

  ${mediaQuery("responsive")`
    padding-top: calc(40px + 66px);
    padding-bottom: 40px;
  `}
`;

export const LayoutLogin = ({
  children,
  layoutFooterProps,
  onVisibilitySensorChange,
}: {
  children: React.ReactNode;
  layoutFooterProps: ILayoutFooterProps;
  onVisibilitySensorChange?: (arg: boolean) => void;
}) => {
  return (
    <>
      <GlobalStyles />
      <Content>{children}</Content>
      <VisibilitySensor onChange={onVisibilitySensorChange}>
        <LayoutFooter {...layoutFooterProps} />
      </VisibilitySensor>
    </>
  );
};
