import * as React from "react";
import styled from "styled-components";

const SvgArrowL10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 50 100" {...props}>
    <path
      className="arrow-l-10_svg__st0"
      d="M31.4 96.8l-28.2-39c-4.3-4.3-4.3-11.2 0-15.5l28.2-39C35.6-1 42.5-1 46.8 3.3c4.2 4.3 4.2 11.2 0 15.5L26.3 50l20.5 31.3c4.2 4.3 4.2 11.2 0 15.5-2.1 2.1-4.9 3.2-7.7 3.2s-5.6-1.1-7.7-3.2z"
    />
  </svg>
);

export default styled(SvgArrowL10)``;
