import * as React from "react";
import styled from "styled-components";

const SvgEuro16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M52.1 25c7.2 0 11.5 2.6 15.1 6.4.7.7 1.5 2 1.5 3.6 0 2.8-2.4 5-5.1 5-1.8 0-3-.8-3.7-1.6-2.2-2.5-4.6-3.9-8-3.9-4.1 0-7.1 2.3-8.8 6.1h9.6c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5H41.4c-.1.8-.1 1.6-.1 2.4 0 .8 0 1.6.1 2.3h11.3c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5h-9.8c1.7 4.1 4.9 6.4 9.3 6.4 3.5 0 5.7-1.5 8.1-4.2.8-1 2-1.7 3.6-1.7 2.6 0 4.8 2.1 4.8 4.8 0 1.2-.6 2.4-1 3-3.8 4.6-8.4 7.5-15.4 7.5-10.6 0-17.8-6.4-20.6-15.8h-3.2c-1.9 0-3.5-1.5-3.5-3.5s1.6-3.5 3.5-3.5h2.1c-.1-.7-.1-1.4-.1-2.1 0-.8 0-1.7.1-2.6h-2.1c-1.9 0-3.5-1.5-3.5-3.5 0-1.9 1.6-3.5 3.5-3.5h3.4C34.8 31.4 41.8 25 52.1 25zM50 12.5c-20.7 0-37.5 16.8-37.5 37.5S29.3 87.5 50 87.5 87.5 70.7 87.5 50 70.7 12.5 50 12.5zM50 0c27.6 0 50 22.4 50 50s-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0z" />
  </svg>
);

export default styled(SvgEuro16)``;
