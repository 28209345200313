import { createGlobalStyle } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFace, fontFamily, fontSize } from "./lib/fonts";

const interpolateDefault = (v: any) => (v && v.default) || v;

const normalize = interpolateDefault(
  // tslint:disable-next-line
  require("!css-loader!normalize.css/normalize.css"),
);
const slick = interpolateDefault(
  // tslint:disable-next-line
  require("!css-loader!slick-carousel/slick/slick.css"),
);
const slickTheme = interpolateDefault(
  // tslint:disable-next-line
  require("!css-loader!slick-carousel/slick/slick-theme.css"),
);
const tippyTheme = interpolateDefault(
  // tslint:disable-next-line
  require("!css-loader!tippy.js/dist/tippy.css"),
);

export const globalStyles = (rootName = "html,body") => `
  ${normalize.toString()}
  ${fontFace(ffdinPro.regular)}
  ${fontFace(ffdinPro.medium)}
  ${fontFace(ffdinPro.bold)}
  ${fontFace(ffdinPro.condensedMedium)}
  ${fontFace(ffdinPro.condensedBold)}

  :root {
    font-size: 16px;
  }

  html,
  body {
    height: 100%;
    ${fontFamily(ffdinPro.regular)}
    ${fontSize("16px")}
    color: ${colors.darkDk};
  }
`

export const GlobalStyles = createGlobalStyle`
  ${slick.toString()}
  ${slickTheme.toString()}
  ${tippyTheme.toString()}

  ${globalStyles()}

  #___gatsby,
  #___gatsby > div:first-child {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  html {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }
`;
