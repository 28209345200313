import { InputProps } from "@material-ui/core/Input";
import React from "react";
import styled from "styled-components";
import { Checkbox } from "./Checkbox";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";

const Container = styled.label`
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${colors.lightLt};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 40px;
  position: relative;
  cursor: pointer;

  ${mediaQuery("phablet")`
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  `}

  ${mediaQuery("tablet")`
    padding: 20px;
  `}

  svg {
    display: block;

    ${mediaQuery("phablet")`
      margin-right: 20px;
    `}
  }
`;

const CheckboxStyled = styled(Checkbox)`
  position: absolute;
  top: 20px;
  right: 20px;

  ${mediaQuery("phablet")`
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  `}
`;

const Children = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  color: ${colors.darkDk};
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 20px;

  ${mediaQuery("phablet")`
     margin-top: 0;
  `}
`;

export interface ICardIconProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  inputProps?: Pick<InputProps, "name" | "onChange">;
}

export const CardIcon = ({ children, icon, inputProps }: ICardIconProps) => {
  const inputId = inputProps?.name ? `cardicon-${inputProps.name}` : undefined;
  return (
    <Container htmlFor={inputId}>
      <CheckboxStyled {...inputProps} id={inputId} />
      <div>{icon}</div>
      <Children>{children}</Children>
    </Container>
  );
};
