import * as React from "react";
import styled from "styled-components";

const SvgRoundedMinus30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="rounded-minus-30_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 100 100"
    {...props}
  >
    <g id="rounded-minus-30_svg__rounded-minus-30">
      <path d="M50 0a50 50 0 1050 50A50 50 0 0050 0zm0 93.33A43.33 43.33 0 1193.33 50 43.38 43.38 0 0150 93.33z" />
      <path d="M63.33 46.67H36.67a3.33 3.33 0 100 6.67h26.66a3.33 3.33 0 100-6.67z" />
    </g>
  </svg>
);

export default styled(SvgRoundedMinus30)``;
