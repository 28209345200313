import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { IconPlus10 } from "./icons-ts";
import { colors } from "./lib/constants";

const Container = styled.div`
  margin-top: 40px;
  position: relative;
  text-align: center;

  &::before {
    background: ${colors.lightLt};
    content: "";
    height: 1px;
    left: 0;
    margin-top: -0.5px;
    position: absolute;
    right: 0;
    z-index: -1;
    top: 50%;
  }
`;

export interface IButtonMoreProps {
  disabled?: boolean;
  onClick?: () => void;
  hidden?: boolean;
}

export const ButtonMore = ({
  disabled,
  onClick = () => {},
  hidden = false,
}: IButtonMoreProps) => (
  <Container>
    {!hidden && (
      <Button
        disabled={disabled}
        onClick={onClick}
        prefix={<IconPlus10 width="10" fill="white" />}
      >
        En voir plus
      </Button>
    )}
  </Container>
);
