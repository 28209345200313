import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import type { ProductType } from "../../../__generated__/globalTypes";
import { CatalogProductsCard } from "./CatalogProductsCard";
import { mediaQuery } from "./lib/media";

export interface ICatalogProduct {
  id: string;
  reference?: string | null;
  price: number | null;
  label: string;
  subLabel?: string | null;
  categories: { label: string }[];
  images?: { src: string }[] | null;
  wholesalers: {
    label: string;
    isClientReferenceRequired: boolean;
    clientReferenceDescription?: string | null;
  }[];
  infoUrl: string | null;
  promo: boolean;
  newProduct: boolean;
  promoPrice?: number | null;
  promoStartDate?: string | null;
  promoEndDate?: string | null;
  shortDescription: string;
  longDescription?: string | null;
  productType: ProductType
}

const ColStyled = styled(Col)`
  display: flex !important;
  flex-direction: column;

  ${mediaQuery("desktop")`
    &:not(:nth-child(-n + 3)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("tablet")`
    &:not(:nth-child(-n + 2)) {
      margin-top: 20px;
    }
  `}

  ${mediaQuery("phablet")`
    flex-basis: 100%;
    max-width: 100%;
    display: block;

    &:not(:first-child) {
      margin-top: 20px;
    }
  `}
`;

export const CatalogProducts = ({
  products,
  onSelect,
  onMouseOver,
  onPurchase,
}: {
  products: ICatalogProduct[];
  onSelect: (id: string) => void;
  onMouseOver: (id: string) => void;
  onPurchase: (id: string) => void;
}) => (
  <Row>
    {products.map((item, i) => (
      <ColStyled xs={12} sm={6} md={4} lg={4} key={i}>
        <CatalogProductsCard
          item={item}
          onSelect={onSelect}
          onMouseOver={onMouseOver}
          onPurchase={onPurchase}
        />
      </ColStyled>
    ))}
  </Row>
);
