import * as React from "react";
import styled from "styled-components";

const SvgCross10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M64.8 50L97 82.2c4.1 4.1 4.1 10.7 0 14.8-4.1 4.1-10.7 4.1-14.8 0L50 64.8 17.8 96.9C13.7 101 7.1 101 3 96.9c-4-4-4-10.7.1-14.7L35.2 50 3.1 17.8C-1 13.8-1 7.1 3.1 3.1c4-4.1 10.7-4.1 14.7 0L50 35.2 82.2 3.1c4-4.1 10.7-4.1 14.7 0 4.1 4.1 4.1 10.7 0 14.8L64.8 50z" />
  </svg>
);

export default styled(SvgCross10)``;
