import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { CatalogDetailSheetCarousel } from "./CatalogDetailSheetCarousel";
import {
  CatalogDetailSheetIntro,
  ICatalogDetailSheetIntroProps,
} from "./CatalogDetailSheetIntro";
import { ICatalogProduct } from "./CatalogProducts";

const ColStyled = styled(Col)`
  display: flex !important;
  flex-direction: column;
`;

export const CatalogDetailSheet = ({
  product,
  catalogDetailSheetIntroProps,
}: {
  product: ICatalogProduct;
  catalogDetailSheetIntroProps: ICatalogDetailSheetIntroProps;
}) => (
  <Row>
    <Col xs={12} sm={12} md={4} lg={4}>
      {product.images && (
        <CatalogDetailSheetCarousel images={product.images.map(i => i.src)} />
      )}
    </Col>
    <ColStyled xs={12} sm={12} mdOffset={1} md={7} lgOffset={1} lg={7}>
      <CatalogDetailSheetIntro {...catalogDetailSheetIntroProps} />
    </ColStyled>
  </Row>
);
