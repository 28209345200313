import { ButtonBase } from "@material-ui/core";
import { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import { CSSProperties } from "@material-ui/styles";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { LogicalLink } from "./LogicalLink";

const ButtonContainer = styled(
  ({ prefix, fullwidth, iconComponent, mini, ...props }) => {
    return <ButtonBase {...props} component={props.to && LogicalLink} />;
  },
)<
  {
    fullwidth?: boolean;
    mini?: boolean;
    theme?: ITheme;
    iconComponent?: React.ReactNode;
    disabled?: boolean;
    href?: string;
    prefix?: boolean;
  } & Omit<ButtonBaseProps, "prefix">
>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  background: ${colors.primary};
  border-radius: 20px;
  color: #fff;
  height: 40px;
  padding: 0 25px;
  transition: box-shadow .2s, transform .2s, background .2s, border-color .2s;
  box-sizing: border-box;

  &:hover {
    box-shadow: rgba(25, 80, 137, 0.1) 0px 4px 8px -1px, rgba(0, 128, 255, 0.08) 0px 8px 24px -2px;
    background: ${colors.primaryDk};
  }

  ${p =>
    p.theme === "dark" &&
    css`
      background: ${colors.darkDk};
      box-shadow: 2px 5px 10px 1px rgba(0, 0, 0, 0.05);
    `}

    ${p =>
      p.prefix &&
      css`
        padding: 0 25px 0 5px;
      `}

  ${p =>
    p.disabled &&
    css`
      background: ${colors.lightLt};
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    `}

  ${p =>
    p.iconComponent &&
    css`
      padding: 0;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
    `}

  ${p =>
    p.theme === "white" &&
    css`
      background: #fff;
      color: ${colors.primary};

      &:hover {
        background: ${colors.lightLt};
      }
    `}

  ${p =>
    p.theme === "bordered" &&
    css`
      background: #fff;
      color: ${colors.primary};
      border: 2px solid ${colors.primary};

      &:hover {
        color: #fff;
        border-color: ${colors.primaryDk};
      }
    `}
  ${p =>
    p.fullwidth &&
    css`
      width: 100%;
    `}
  ${p =>
    p.mini &&
    css`
      height: 30px;
      padding: 0 15px;
    `}
`;

const Prefix = styled.div<{ disabled: boolean }>`
  align-items: center;
  background: ${colors.primaryDk};
  border-radius: 15px;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 15px;
  width: 30px;
  transition: background 0.2s;

  ${p =>
    p.disabled &&
    css`
      background: ${colors.lightLt};
    `}
`;

type ITheme = "dark" | "white" | "bordered";

export const Button = ({
  children,
  fullwidth,
  mini,
  prefix,
  theme,
  icon,
  onClick,
  className,
  type,
  style,
  disabled,
  to,
  href,
}: {
  children?: React.ReactNode;
  fullwidth?: boolean;
  mini?: boolean;
  prefix?: React.ReactNode;
  theme?: ITheme;
  icon?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  style?: CSSProperties;
  disabled?: boolean;
  to?: string;
  href?: string;
}) => {
  return (
    <ButtonContainer
      disabled={!!disabled}
      href={href}
      to={to}
      style={style}
      className={className}
      type={type}
      fullwidth={fullwidth}
      mini={mini}
      iconComponent={icon}
      onClick={onClick}
      theme={theme}
      prefix={!!prefix as any}
    >
      {prefix && <Prefix disabled={!!disabled}>{prefix}</Prefix>}
      {children}
      {icon && icon}
    </ButtonContainer>
  );
};
