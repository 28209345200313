import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";

const Title = styled.div<{ subTitle: boolean }>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("35px")}
  color: ${colors.darkDk};
  letter-spacing: -1.5px;
  flex: 1;

  ${mediaQuery("phablet")`
    ${fontSize("19px")}
    text-align: center;
  `}

  ${p =>
    p.subTitle &&
    css`
      ${mediaQuery("phablet")`
      ${fontSize("25px")}
    `}
    `}
`;

const Container = styled.div<{ subTitle: boolean }>`
  margin-bottom: 60px;

  ${mediaQuery("phablet")`
    margin-bottom: 20px;
    margin-top: -20px;
  `}

  ${p =>
    p.subTitle &&
    mediaQuery("phablet")`
      margin-bottom: 40px;
    `}
`;

const TitleContainer = styled.div<{ subTitle: boolean; center?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${p =>
    p.subTitle &&
    css`
      text-align: center;
    `}

  ${p =>
    p.center &&
    css`
      text-align: center;
    `}
`;

const SubTitle = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("19px")}
  color: ${colors.darkDk};
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 15px;

  ${mediaQuery("phablet")`
    ${fontSize("16px")}
    margin-top: 10px;
  `}
`;

export const PageTitle = ({
  children,
  addon,
  subTitle,
  center,
  className,
}: {
  children: React.ReactNode;
  addon?: React.ReactNode;
  subTitle?: React.ReactNode;
  center?: boolean;
  className?: string;
}) => (
  <Container subTitle={!!subTitle}>
    <TitleContainer center={center} subTitle={!!subTitle} className={className}>
      <Title subTitle={!!subTitle}>{children}</Title>
      {addon && addon}
    </TitleContainer>
    {subTitle && <SubTitle>{subTitle}</SubTitle>}
  </Container>
);
