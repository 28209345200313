import { omit } from "lodash";
import React from "react";
import { mapProps } from "recompose";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { ILogicalLinkProps, LogicalLink } from "./LogicalLink";

const LinkContainer = styled(
  mapProps((p: ILogicalLinkProps) =>
    omit(p, ["dark", "icon", "borderless", "white"]),
  )(LogicalLink),
)<{
  dark?: boolean;
  icon?: boolean;
  borderless?: boolean;
  white?: boolean;
}>`
  color: ${colors.primary};
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s, border-color 0.2s;
  display: inline-block;

  ${(p) =>
    !p.borderless &&
    css`
      border-bottom: 1px solid ${colors.primaryLt};
    `}

  ${(p) =>
    p.icon &&
    css`
      display: inline-flex;
      align-items: center;
    `}

  &:hover {
    color: ${colors.primaryDk};

    ${(p) =>
      !p.borderless &&
      css`
        border-color: ${colors.primaryDk};
      `}
  }

  ${(p) =>
    p.dark &&
    css`
      color: ${colors.darkDk};
      border-color: ${colors.lightLt};
    `}

  ${(p) =>
    p.white &&
    css`
      color: #fff;
      border-color: #f24d60;
      &:hover {
        color: #fff;
        border-color: #fff;
      }
    `}
`;

const Icon = styled.div<{ dark: boolean }>`
  margin-left: 10px;

  svg {
    fill: ${colors.primary};

    ${(p) =>
      p.dark &&
      css`
        fill: ${colors.darkDk};
      `}
  }
`;

export const Link = ({
  children,
  href,
  to,
  dark,
  icon,
  onClick,
  className,
  borderless,
  target,
  style,
  white,
}: {
  children: React.ReactNode;
  href?: string;
  to?: string;
  dark?: boolean;
  white?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  borderless?: boolean;
  target?: string;
  style?: React.CSSProperties;
}) => (
  <LinkContainer
    className={className}
    onClick={onClick}
    dark={!!dark}
    white={white}
    href={href}
    to={to}
    icon={!!icon}
    borderless={!!borderless}
    target={target}
    style={style}
  >
    {children}
    {icon && <Icon dark={!!dark}>{icon}</Icon>}
  </LinkContainer>
);
