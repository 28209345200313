import * as React from "react";
import styled from "styled-components";

const SvgRoundedCheck16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M72.8 33.4c2.3 2.3 2.5 6 .3 8.5l-21.9 22C49 66.1 46 67.3 43 67.3v-.1c-3.1 0-6.1-1.2-8.2-3.4l-8.3-9.9c-1.9-2.7-1.5-6.3 1.1-8.4 2.5-2.1 6.2-1.9 8.5.4l7 8.4 21.2-21.2c2.5-2.2 6.2-2 8.5.3zM50 12.5c-20.7 0-37.5 16.8-37.5 37.5S29.3 87.5 50 87.5 87.5 70.7 87.5 50 70.7 12.5 50 12.5zM50 0c27.6 0 50 22.4 50 50 0 13.3-5.3 26-14.6 35.4C76 94.7 63.3 100 50 100 22.4 100 0 77.6 0 50S22.4 0 50 0z" />
  </svg>
);

export default styled(SvgRoundedCheck16)``;
