import React from "react";
import styled, { css } from "styled-components";
import { IconPadlock16 } from "./icons-ts";
import { ILayoutItemNavLink } from "./Layout";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { LogicalLink } from "./LogicalLink";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const MenuItem = styled(LogicalLink)<{ active?: boolean }>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  color: ${colors.darkDk};
  position: relative;
  text-decoration: none;
  transition: color .2s;

  &::before {
    background: ${colors.primary};
    border-radius: 1px;
    bottom: -15px;
    content: "";
    height: 2px;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity .3s, width .2s;
    width: 0;
  }

  &:not(:first-child) { margin-left: 28px; }

  &:hover {
    color: ${colors.primary};

    &::before {
      opacity: 1;
      width: 20px;
    }
  }

  ${p =>
    p.active &&
    css`
      color: ${colors.primary};

      &::before {
        opacity: 1;
        width: 20px;
      }
    `}
`;

const PartnerContainer = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  align-items: center;
  color: ${colors.primary};
  display: flex;
`;

export const LinkPartner = styled(Link)`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("12px")}
  margin-left: 15px;
  cursor: pointer;
`;

const Logout = styled.div`
  margin-left: 30px;
  cursor: pointer;

  svg {
    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: ${colors.primaryDk};
    }
  }
`;

const PartnerName = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("12px")}
  line-height: 14px;
`;

const Options = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  background: ${colors.lightLter};
  border-radius: 0 0 10px 10px;
  padding: 0 15px;
  display: flex;
  align-items: center;
`;

export interface ILayoutNavigationProps {
  navLinks: ILayoutItemNavLink[];
  partnerName?: string;
  canChangePartner?: boolean;
  onPartnerChangeClick: () => void;
  onLogoutClick: () => void;
}

export const LayoutNavigation = ({
  navLinks,
  partnerName,
  canChangePartner,
  onPartnerChangeClick,
  onLogoutClick,
}: ILayoutNavigationProps) => (
  <>
    <Container>
      {navLinks.map((item, i) => (
        <MenuItem
          active={!!item.active}
          to={item.to}
          key={i}
          target={item.target}
        >
          {item.label}
        </MenuItem>
      ))}
      <Options>
        <PartnerContainer>
          <PartnerName>{partnerName}</PartnerName>
          {canChangePartner && (
            <LinkPartner onClick={onPartnerChangeClick}>Changer</LinkPartner>
          )}
        </PartnerContainer>
        <Logout onClick={onLogoutClick}>
          <IconPadlock16 width={14} height={16} fill={colors.primary} />
        </Logout>
      </Options>
    </Container>
  </>
);
