import React from "react";
import styled, { css } from "styled-components";
import { IUseInputResult } from "./Form";
import { IconBox20, IconQuestion16, IconTruck20 } from "./icons-ts";
import { Input } from "./Input";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Tipped } from "./Tipped";

export interface IShippingDetailProps {
  isImmediateDelivery: boolean;
  wholesalerName?: string;
  name: string;
  street: string | null;
  street2: string | null;
  zip: string | null;
  city: string | null;
  country: string | null;
  number: string | null;
  showImmediateDeliveryMessage?: boolean;
  clientReferenceInputProps?: React.ComponentProps<typeof InputClientReference>;
  large?: boolean;
}

export const ShippingDetail = ({
  isImmediateDelivery,
  wholesalerName,
  name,
  street,
  city,
  number,
  zip,
  showImmediateDeliveryMessage,
  clientReferenceInputProps,
  large,
}: IShippingDetailProps) => (
  <ShippingDetailContainer flex={!!clientReferenceInputProps || !!large}>
    <div>
      <ShippingTitle>
        <IconBox20 width={20} height={20} fill={colors.darkDk} /> Fournisseur
      </ShippingTitle>
      <div style={{ marginBottom: 30 }}>{wholesalerName}</div>
      {clientReferenceInputProps ? (
        <InputClientReference
          {...clientReferenceInputProps}
          wholesalerName={wholesalerName}
        />
      ) : null}
    </div>
    <div>
      <ShippingTitle>
        <IconTruck20 width={20} height={20} fill={colors.darkDk} /> Livraison{" "}
        {isImmediateDelivery ? "immédiate" : ""}
      </ShippingTitle>
      <div>
        {!isImmediateDelivery && showImmediateDeliveryMessage && (
          <TextInfo style={{ marginBottom: 10 }}>
            Vous serez livré avec votre prochaine commande par le fournisseur{" "}
            {wholesalerName}
          </TextInfo>
        )}
        <ShippingName>{name}</ShippingName>
        <ShippingAddress>
          {number} {street}
        </ShippingAddress>
        <ShippingAddress>
          {zip} {city}
        </ShippingAddress>
      </div>
    </div>
  </ShippingDetailContainer>
);

const ShippingDetailContainer = styled.div<{ flex?: boolean }>`
  ${(p) =>
    p.flex &&
    css`
      display: flex;

      > div {
        width: 50%;

        &:first-child {
          padding-right: 40px;
        }
      }
    `}
`;

const InputClientReference = ({
  description,
  wholesalerName,
  ...props
}: { description?: string; wholesalerName?: string } & IUseInputResult) => (
  <>
    {!!wholesalerName && (
      <TextInfo>
        Veuillez compléter votre référence client du fournisseur{" "}
        {wholesalerName}
      </TextInfo>
    )}
    <Input
      style={{ marginTop: 15 }}
      {...props}
      label="Référence client"
      fullWidth
      icon={
        description ? (
          <InputClientReferenceDesc text={description} />
        ) : undefined
      }
    />
  </>
);

const InputClientReferenceDesc = ({ text }: { text: string }) => (
  <Tipped content={<TippedContent>{text}</TippedContent>}>
    <IconQuestion16 fill={colors.dark} />
  </Tipped>
);

const ShippingTitle = styled.div`
  display: flex;
  align-items: center;
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
  margin-bottom: 20px;

  ${IconBox20}, ${IconTruck20} {
    margin-right: 15px;
  }
`;

const ShippingAddress = styled.div`
  ${fontSize("14px")}

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

const ShippingName = styled(ShippingAddress)`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
`;

const TextInfo = styled.div`
  ${fontSize("12px")}
  color: ${colors.dark}
`;

const TippedContent = styled.div`
  white-space: break-spaces;
`;
