import * as React from "react";
import styled from "styled-components";

const SvgMinus10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 20" {...props}>
    <path d="M90 0c5.5 0 10 4.5 10 10s-4.5 10-10 10H10C4.5 20 0 15.5 0 10S4.5 0 10 0h80z" />
  </svg>
);

export default styled(SvgMinus10)``;
