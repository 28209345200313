import * as React from "react";
import styled from "styled-components";

const SvgUser16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M13.1 87.5c3.2-14.8 17.7-25 36.9-25s33.8 10 36.9 25H13.1zm18.1-56.2c0-10.3 8.4-18.7 18.8-18.7S68.8 21 68.8 31.3C68.8 41.7 60.4 50 50 50s-18.8-8.3-18.8-18.7zm40.2 22.6c9.3-8.8 12.3-22.3 7.6-34.2C74.3 7.8 62.8 0 50 0S25.7 7.8 21 19.7s-1.7 25.4 7.6 34.2C11.5 59.8 0 75.8 0 93.8c0 3.4 2.8 6.2 6.2 6.2h87.5c3.5 0 6.2-2.8 6.2-6.2.1-18.1-11.4-34.1-28.5-39.9z" />
  </svg>
);

export default styled(SvgUser16)``;
