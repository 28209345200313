import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled, { css } from "styled-components";
import { GlobalStyles } from "./GlobalStyles";
import { ILayoutFooterProps, LayoutFooter } from "./LayoutFooter";
import { ILayoutHeaderProps, LayoutHeader } from "./LayoutHeader";
import {
  ILayoutHeaderMobileProps,
  LayoutHeaderMobile,
} from "./LayoutHeaderMobile";
import { ILayoutMenuMobileProps, LayoutMenuMobile } from "./LayoutMenuMobile";
import { Media, mediaQuery } from "./lib/media";
import { ILoginAsBannerProps, LoginAsBanner } from "./LoginAsBanner";

export interface ILayoutItemNavLink {
  label: string;
  to: string;
  active: boolean;
  target?: string;
}
export interface ILayoutItemFooterLink {
  active: boolean;
  priority: number;
  label: {
    [key: string]: string;
  };
  target?: "_blank";
  url: {
    [key: string]: string;
  };
}

const Content = styled.div<{
  noPaddingTop?: boolean;
  ref: any;
  loginAs?: boolean;
}>`
  flex: 1 0 auto;
  padding-bottom: 100px;
  padding-top: 60px;

  ${(p) =>
    p.noPaddingTop &&
    css`
      padding-top: 0;

      @media only screen and (max-width: 960px) {
        padding-top: 66px;
      }
    `}

  ${mediaQuery("responsive")`
    padding-top: calc(40px + 66px);
    padding-bottom: 40px;

    ${(p: any) =>
      p.noPaddingTop &&
      css`
        padding-top: 66px;
      `}
  `}
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Layout = React.forwardRef(
  (
    {
      children,
      noPaddingTop,
      layoutHeaderProps,
      layoutHeaderMobileProps,
      layoutMenuMobileProps,
      layoutFooterProps,
      onVisibilitySensorChange,
      loginAs,
      loginAsBannerProps,
    }: {
      children: React.ReactNode;
      noPaddingTop?: boolean;
      layoutHeaderProps: ILayoutHeaderProps;
      layoutHeaderMobileProps: ILayoutHeaderMobileProps;
      layoutMenuMobileProps: ILayoutMenuMobileProps;
      layoutFooterProps: ILayoutFooterProps;
      onVisibilitySensorChange?: (arg: boolean) => void;
      loginAs?: boolean;
      loginAsBannerProps: ILoginAsBannerProps;
    },
    ref,
  ) => {
    return (
      <>
        {loginAs && (
          <Media when={">960"}>
            <LoginAsBanner {...loginAsBannerProps} />
          </Media>
        )}
        <Container>
          <GlobalStyles />
          <>
            <Media when={">960"}>
              <LayoutHeader {...layoutHeaderProps} />
            </Media>
            <Media when={"<=960"}>
              <LayoutHeaderMobile {...layoutHeaderMobileProps} />
              <LayoutMenuMobile {...layoutMenuMobileProps} />
            </Media>
          </>
          <Content ref={ref} noPaddingTop={noPaddingTop} loginAs={loginAs}>
            {children}
          </Content>
          <VisibilitySensor
            partialVisibility
            onChange={onVisibilitySensorChange}
          >
            <LayoutFooter {...layoutFooterProps} />
          </VisibilitySensor>
        </Container>
      </>
    );
  },
);

export const EmptyLayout = React.forwardRef(
  ({ children }: { children: React.ReactNode }, ref) => (
    <Container>
      <GlobalStyles />
      <Content ref={ref}>{children}</Content>
    </Container>
  ),
);
