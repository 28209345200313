import * as React from "react";
import styled from "styled-components";

const SvgRoundedPlus30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="rounded-plus-30_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 100 100"
    {...props}
  >
    <g id="rounded-plus-30_svg__rounded-plus-30">
      <path d="M50 0a50 50 0 1050 50A50 50 0 0050 0zm0 93.33A43.33 43.33 0 1193.33 50 43.38 43.38 0 0150 93.33z" />
      <path d="M63.33 46.67h-10v-10a3.33 3.33 0 00-6.67 0v10h-10a3.33 3.33 0 100 6.67h10v10a3.33 3.33 0 106.67 0v-10h10a3.33 3.33 0 100-6.67z" />
    </g>
  </svg>
);

export default styled(SvgRoundedPlus30)``;
