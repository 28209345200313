import React from "react";
import styled from "styled-components";
import { IconArrowL10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { mediaQuery } from "./lib/media";
import { Link } from "./Link";

const Container = styled.div`
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery("phablet")`
    margin-top: 40px;
    flex-direction: column-reverse;
    align-items: initial;
  `}
`;

const Children = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  justify-content: flex-end;
  flex: 1;

  ${mediaQuery("phablet")`
    flex-direction: column-reverse;

    button + button { margin-bottom: 20px; }
  `}

  > button,
  span,
  a {
    &:last-child {
      margin-left: auto;
    }
  }

  ${mediaQuery("phablet")`
    > button,
    > a { width: 100%; }
  `}
`;

const Icon = styled(IconArrowL10)`
  margin-right: 10px;
`;

const BackLink = styled(Link)`
  ${mediaQuery("phablet")`
    margin-top: 20px;
    text-align: center;
  `}
`;

export const LittersSubmit = ({
  children,
  backLink,
}: {
  children: React.ReactNode;
  backLink?: string;
}) => (
  <Container>
    {backLink && (
      <BackLink borderless href={backLink}>
        <Icon height={10} width={5} fill={colors.primary} />
        Retour
      </BackLink>
    )}
    <Children>{children}</Children>
  </Container>
);
