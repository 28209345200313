import React from "react";
import styled, { css } from "styled-components";
import { Alert } from "./Alert";
import { Button } from "./Button";
import {
  IconPlus10,
  IconRefresh20,
  IconRoundedCheck20,
  IconRoundedCross20,
} from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { Link } from "./Link";
import { PageTitle } from "./PageTitle";
import { Tag } from "./Tag";

export interface ILitterItem {
  id: string;
  isMaxPublicationDatePast?: boolean;
  pet: {
    breed: string | null;
    number: number;
    dob: string;
  };
  kit?: {
    status: string;
    quantity: number;
    label: string | null;
  };
  ad?: {
    status: string;
    date: string;
    link: string;
    url?: string | null;
    shouldUpdate?: boolean;
  };
}

export const Flex = styled.div<{ flexStart?: boolean }>`
  display: flex;
  align-items: center;

  ${(p) =>
    p.flexStart &&
    css`
      align-items: flex-start;
    `}
`;

export const Bold = styled.span`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
`;

export const Quantity = styled(Bold)`
  margin-right: 10px;
`;

export const Icon = styled.span`
  display: inline-block;
  margin-right: 15px;

  svg {
    display: block;
  }
`;

export const LinkAd = styled(Link)`
  margin-left: 10px;
`;

export const Status = styled.div`
  margin-left: 10px;
`;

export const Muted = styled.div<{ offset?: number }>`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  color: ${colors.dark};

  ${(p) =>
    p.offset &&
    css`
      margin-top: ${p.offset}px;
    `}
`;

const Table = styled.table`
  border-collapse: collapse;
  padding: 0;
  width: 100%;

  th {
    ${fontFamily(ffdinPro.bold)}
    ${fontSize("14px")}
    border-bottom: 1px solid ${colors.lightLt};
    color: ${colors.dark};
    padding: 0 0 20px 0;
  }

  tbody tr {
    td:not(:first-child) { border-left: 1px solid ${colors.lightLt}; }
  }

  td {
    padding: 40px 20px;
    border-bottom: 1px solid ${colors.lightLt};

    ${mediaQuery("tablet")`
      padding: 20px 10px;
    `}

    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; text-align: right; }
  }
`;

export const Kits = ({ item }: { item: ILitterItem["kit"] }) => (
  <>
    {item ? (
      <Flex>
        <div>
          <Icon>
            <IconRoundedCheck20 fill={colors.success} width={20} />
          </Icon>
        </div>
        <div>
          <Flex>
            <Bold>
              {item.quantity}{" "}
              {item.quantity > 1 ? "kits commandés" : "kit commandé"}
            </Bold>
            {item.status === "pending" && (
              <Status>
                <Tag size="xs" theme="warning">
                  En cours
                </Tag>
              </Status>
            )}
            {item.status === "success" && (
              <Status>
                <Tag size="xs" theme="success">
                  Traité
                </Tag>
              </Status>
            )}
          </Flex>
          <Muted offset={10}>{item.label}</Muted>
        </div>
      </Flex>
    ) : (
      <Flex>
        <Icon>
          <IconRoundedCross20 fill={colors.primaryDk} width={20} height={20} />
        </Icon>
        <Bold>Pas de commande de kits</Bold>
      </Flex>
    )}
  </>
);

export interface IAdsLinks {
  archived: string;
  published: string;
}

export const Ads = ({
  item,
  adsLinks,
}: {
  item: ILitterItem;
  adsLinks: IAdsLinks;
}) => (
  <>
    {item && item.ad ? (
      <>
        {item.ad.status === "archived" &&
          (item.ad.shouldUpdate ? (
            <AdsStatus
              icon={<IconRefresh20 fill={colors.warning} width={20} />}
              iconAnimated
              status="Archivage en cours"
              info={`Depuis le ${item.ad.date}`}
            />
          ) : (
            <AdsStatus
              icon={
                <IconRoundedCheck20
                  fill={colors.light}
                  width={20}
                  height={20}
                />
              }
              status="Annonce archivée"
              info={`Depuis le ${item.ad.date}`}
              linkAdTo={
                item.isMaxPublicationDatePast ? undefined : adsLinks.archived
              }
              linkAdLabel="Réactiver"
            />
          ))}
        {item.ad.status === "published" &&
          (item.ad.shouldUpdate ? (
            <AdsStatus
              icon={<IconRefresh20 fill={colors.warning} width={20} />}
              iconAnimated
              status="Publication en cours"
              info={`Jusqu'au ${item.ad.date}`}
              linkAdTo={adsLinks.published}
              linkAdLabel="Modifier"
            />
          ) : (
            <AdsStatus
              icon={<IconRoundedCheck20 fill={colors.success} width={20} />}
              status="Annonce visible"
              info={`Jusqu'au ${item.ad.date}`}
              linkAdTo={adsLinks.published}
              linkAdLabel="Modifier"
              linkTo={item.ad.url}
              linkLabel="Voir l'annonce"
            />
          ))}
      </>
    ) : (
      <Flex>
        <Icon>
          <IconRoundedCross20 fill={colors.primaryDk} width={20} />
        </Icon>{" "}
        <Bold>Pas d'annonce publiée</Bold>
      </Flex>
    )}
  </>
);

const AdsStatus = ({
  icon,
  iconAnimated,
  status,
  linkTo,
  linkLabel,
  info,
  linkAdTo,
  linkAdLabel,
}: {
  icon: JSX.Element;
  iconAnimated?: boolean;
  status: string;
  linkTo?: string | null;
  linkLabel?: string;
  info?: string;
  linkAdTo?: string;
  linkAdLabel?: string;
}) => (
  <Flex>
    <AdsStatusIcon icon={icon} animated={iconAnimated} />
    <div>
      <Bold>{status}</Bold>
      {linkTo && (
        <Link to={linkTo} target="_blank" style={{ marginLeft: 10 }}>
          {linkLabel}
        </Link>
      )}
      <Flex>
        <Muted offset={10}>
          {info}
          {linkAdTo && (
            <LinkAd dark to={linkAdTo}>
              {linkAdLabel}
            </LinkAd>
          )}
        </Muted>
      </Flex>
    </div>
  </Flex>
);

const AdsStatusIcon = styled(
  ({
    icon,
    className,
  }: {
    icon: JSX.Element;
    animated?: boolean;
    className?: string;
  }) => (
    <div className={className}>
      <Icon>{icon}</Icon>
    </div>
  ),
)`
  ${(p) =>
    p.animated &&
    css`
      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      img,
      svg {
        animation: rotating 1.5s linear infinite;
      }
    `}
`;

const LittersListItem = ({
  item,
  adsLinks,
  getCompleteLink,
}: {
  item: ILitterItem;
  getCompleteLink: (item: ILitterItem) => string | undefined;
  adsLinks: (item: ILitterItem) => IAdsLinks;
}) => {
  const completeLink = getCompleteLink(item);
  return (
    <tr key={item.id}>
      <td>
        <Flex flexStart>
          <Quantity>{item.pet.number}</Quantity>
          <div>
            <Bold>{item.pet.breed || "-"}</Bold>
            <Muted offset={10}>Nés le {item.pet.dob}</Muted>
          </div>
        </Flex>
      </td>
      <td>
        <Kits item={item.kit} />
      </td>
      <td>
        <Ads item={item} adsLinks={adsLinks(item)} />
      </td>
      <td>
        {completeLink && (
          <Button mini to={getCompleteLink(item)}>
            Compléter
          </Button>
        )}
      </td>
    </tr>
  );
};

export const LittersListItemDefaultTransformer = ({
  item,
  children,
}: {
  item: ILitterItem;
  children: ({ item }: { item: ILitterItem }) => JSX.Element;
}) => children({ item });

export const LittersList = ({
  litters,
  newLitterButtonLink,
  loading,
  getCompleteLink,
  adsLinks,
  ItemTransformerComponent: ProvidedItemTransformer,
}: {
  litters: ILitterItem[];
  newLitterButtonLink?: string;
  loading?: boolean;
  getCompleteLink: (item: ILitterItem) => string | undefined;
  adsLinks: (item: ILitterItem) => IAdsLinks;
  ItemTransformerComponent: typeof LittersListItemDefaultTransformer;
}) => {
  const ItemTransformer =
    ProvidedItemTransformer || LittersListItemDefaultTransformer;
  return (
    <>
      <PageTitle
        addon={
          newLitterButtonLink ? (
            <Button
              to={newLitterButtonLink}
              prefix={<IconPlus10 width={10} height={10} fill="white" />}
            >
              Nouvelle déclaration
            </Button>
          ) : null
        }
      >
        Mes déclarations de portées
      </PageTitle>
      {!!litters.length || loading ? (
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Commande de kits</th>
              <th>Publication d'annonce</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {litters.map((item) => (
              <ItemTransformer key={item.id} item={item}>
                {(props) => (
                  <LittersListItem
                    {...props}
                    adsLinks={adsLinks}
                    getCompleteLink={getCompleteLink}
                  />
                )}
              </ItemTransformer>
            ))}
          </tbody>
        </Table>
      ) : (
        <>
          <Alert>Vous n'avez pas encore de portées déclarées</Alert>
        </>
      )}
    </>
  );
};
