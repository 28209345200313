import * as React from "react";
import styled from "styled-components";

const SvgTruck20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={18} {...props}>
    <path
      d="M20 13a3.001 3.001 0 01-2.128 2.871 3.002 3.002 0 01-5.701.13H7.829a3.001 3.001 0 01-5.7-.13A3.002 3.002 0 010 13V3a3 3 0 013-3h8a2 2 0 011.995 1.85L13 2l7 6v5zM5 14a1 1 0 100 2 1 1 0 000-2zm10 0a1 1 0 100 2 1 1 0 000-2zM10.999 2H3a1 1 0 00-.993.883L2 3v10c0 .276.112.526.293.707l.012-.026a3 3 0 015.524.32h4.342a3.001 3.001 0 015.524-.32l.012.026a.997.997 0 00.286-.59L18 13v-3h-5a2 2 0 01-2-2l-.001-6zM13 4.634V7a1 1 0 001 1h2.927L13 4.634z"
      fill="#333"
      fillRule="nonzero"
    />
  </svg>
);

export default styled(SvgTruck20)``;
