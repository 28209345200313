import * as React from "react";
import styled from "styled-components";

const SvgBox20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M62.3 33.8L29.8 19.4 15.9 25 50 38.8l12.3-5zM84.1 25L50 11.2l-9.7 4 30.5 15.3L84.1 25zM.5 22.9c.5-1.1 1.4-2 2.6-2.5L50 0l46.9 20.4c1.9.8 3.1 2.7 3.1 4.6v50l-50 25L0 75V25c0-.7.2-1.4.5-2.1zM55 47.8v39.7L90 70V32.6L55 47.8zM10 32.6V70l35 17.5V47.8L10 32.6z" />
  </svg>
);

export default styled(SvgBox20)``;
