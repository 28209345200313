import { CircularProgress } from "@material-ui/core";
import { pointsToCouronnes } from "@royalcanin-fr-loyalty/common";
import React from "react";
import styled from "styled-components";
import { ProductType } from "../../../__generated__/globalTypes";
import { ICatalogProduct } from "./CatalogProducts";
import { IconInfo16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { IShippingDetailProps, ShippingDetail } from "./ShippingDetail";
import { Tipped } from "./Tipped";

const Container = styled.div`
  margin-top: 20px;
  display: flex;

  ${mediaQuery("phablet")`
    display: block;
  `}
`;

const Col = styled.div`
  &:first-child {
    flex: 1;
  }

  &:not(:first-child) {
    margin-left: 30px;
    width: 35%;
  }

  ${mediaQuery("phablet")`
    width: 100%;

    &:not(:first-child) {
      border-top: 1px solid ${colors.lightLt};
      padding-top: 30px;
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
    }
  `}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr td:last-child {
    text-align: right;
  }

  tr {
    &:not(:last-child) td {
      padding-bottom: 20px;
    }
  }

  tr:last-child td {
    padding-top: 20px;
    border-top: 1px dotted ${colors.light};
  }
`;

const Tooltip = styled.div`
  margin-left: 10px;
`;

const TableTitle = styled.span`
  display: flex;
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
`;

const TableValue = styled.span`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
`;

const TablePoints = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PointsPromo = styled.div`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("14px")}
  color: ${colors.light};
  letter-spacing: -0.5px;
  text-decoration: line-through;
  margin-right: 10px;
`;

const TableTotalLabel = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  ${fontSize("25px")}
  letter-spacing: -0.5px;
`;

const TableTotal = styled.div`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("25px")}
  letter-spacing: -0.5px;
`;

export const ModalOrderSummary = ({
  product,
  quantity,
  tippedContent,
  shippingAddress,
  shippingAddressLoading,
  clientReferenceInputProps,
}: {
  product: ICatalogProduct;
  quantity: number;
  tippedContent?: string;
  shippingAddress?: IShippingDetailProps;
  shippingAddressLoading?: boolean;
  clientReferenceInputProps?: React.ComponentProps<
    typeof ShippingDetail
  >["clientReferenceInputProps"];
}) => (
  <Container>
    <Col>
      {shippingAddressLoading && <CircularProgress color="primary" />}
      {shippingAddress && (
        <ShippingDetail
          showImmediateDeliveryMessage
          {...shippingAddress}
          clientReferenceInputProps={clientReferenceInputProps}
          large
          isImmediateDelivery={
            shippingAddress.isImmediateDelivery ||
            product.productType === ProductType.premia
          }
        />
      )}
    </Col>
    <Col>
      <Table>
        <tbody>
          <tr>
            <td>
              <TableTitle>
                Valeur unitaire
                {tippedContent && (
                  <Tooltip>
                    <Tipped content={tippedContent}>
                      <IconInfo16 fill={colors.light} width={16} height={16} />
                    </Tipped>
                  </Tooltip>
                )}
              </TableTitle>
            </td>
            <td>
              <TablePoints>
                {product.promo ? (
                  <>
                    <PointsPromo>
                      {pointsToCouronnes(product.price)} couronnes
                    </PointsPromo>
                    <TableValue>
                      {pointsToCouronnes(product.promoPrice)} couronnes
                    </TableValue>
                  </>
                ) : (
                  <TableValue>
                    {pointsToCouronnes(product.price)} couronnes
                  </TableValue>
                )}
              </TablePoints>
            </td>
          </tr>
          <tr>
            <td>
              <TableTitle>Quantité</TableTitle>
            </td>
            <td>
              <TableValue>{quantity}</TableValue>
            </td>
          </tr>
          <tr>
            <td>
              <TableTotalLabel>Total</TableTotalLabel>
            </td>
            <td>
              <TableTotal>
                {pointsToCouronnes(
                  product.promo
                    ? (product.promoPrice || 0) * quantity
                    : (product.price || 0) * quantity,
                )}{" "}
                couronnes
              </TableTotal>
            </td>
          </tr>
        </tbody>
      </Table>
    </Col>
  </Container>
);
