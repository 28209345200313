import React from "react";
import styled from "styled-components";
import { IconCross16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const HeaderTitle = styled.div`
  letter-spacing: -0.5px;
  color: ${colors.darkDk};
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("25px")}

  ${mediaQuery("phablet")`
    ${fontSize("19px")}
  `}
`;

const ModalClose = styled.div`
  cursor: pointer;
  margin-left: auto;

  svg {
    display: block;
    transition: fill 0.2s;
  }

  &:hover svg {
    fill: ${colors.primary};
  }
`;

export const ModalHeader = ({
  onClose,
  children,
}: {
  onClose: () => void;
  children?: React.ReactNode;
}) => (
  <Header>
    {children && <HeaderTitle>{children}</HeaderTitle>}
    <ModalClose onClick={onClose}>
      <IconCross16 fill={colors.darkDk} width={16} height={16} />
    </ModalClose>
  </Header>
);
