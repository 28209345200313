import { Checkbox as MUICheckbox } from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import React from "react";
import styled from "styled-components";
import { IUseInputResult } from "./Form";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.label`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
  cursor: pointer;
`;

export type ICheckboxProps = CheckboxProps &
  Partial<IUseInputResult> & {
    children?: React.ReactNode | React.ReactChildren;
  };

export const Checkbox = ({
  children,
  className,
  error,
  helperText,
  onChange,
  ...props
}: ICheckboxProps) => {
  return (
    <Container className={className}>
      <MUICheckbox
        style={{ marginLeft: -12, marginTop: -12, marginBottom: -12 }}
        onChange={onChange}
        {...props}
      />
      <Text htmlFor={props.id}>
        {children}
      </Text>
    </Container>
  );
};
