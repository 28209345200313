import * as React from "react";
import styled from "styled-components";

const SvgArrowT16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 44" {...props}>
    <path d="M98 32.3L54.9 2c-2.7-2.7-7-2.7-9.7 0L2 32.3C-.7 35-.7 39.3 2 42c2.7 2.7 7 2.7 9.7 0L50 16.5 88.3 42c2.7 2.7 7 2.7 9.7 0 1.3-1.3 2-3.1 2-4.8s-.7-3.5-2-4.9z" />
  </svg>
);

export default styled(SvgArrowT16)``;
