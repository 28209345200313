module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"RC Loyalty","name":"Royal Canin Loyalty","icon":"./assets/icon.png","background_color":"#EE282E","display":"fullscreen","theme_color":"#EE282E"},
    },{
      plugin: require('../../../node_modules/@loicmahieu/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://8f40b95e6ad99b44cb789eb63d9f6411@o1400457.ingest.sentry.io/4506388908802048"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PRXCS5R","includeInDevelopment":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"api"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
