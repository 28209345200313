import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import frLocale from "date-fns/locale/fr";
import React from "react";

export const PickersUtilsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
    {children}
  </MuiPickersUtilsProvider>
);
