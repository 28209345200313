import Slider from "@material-ui/core/Slider";
import React from "react";
import styled from "styled-components";

const SliderContainer = styled(Slider)`
  display: block;
`;

function valuetext(value: number) {
  return `${value}`;
}

export const SliderRange = ({
  value,
  range,
  onChange,
}: {
  value: (string | number)[];
  range: {
    min: number;
    max: number;
  };
  onChange: (data: number[]) => void;
}) => {
  const handleChange = (event: any, newValue: number | number[]) => {
    onChange(newValue as number[]);
  };

  return (
    <SliderContainer
      value={[
        (value && typeof value[0] && parseInt(value[0].toString(), 10)) || 0,
        (value && typeof value[1] && parseInt(value[1].toString(), 10)) || 0,
      ]}
      onChange={handleChange}
      min={range.min}
      max={range.max}
      valueLabelDisplay="auto"
      aria-labelledby="range-slider"
      getAriaValueText={valuetext}
    />
  );
};
