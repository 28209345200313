import * as React from "react";
import styled from "styled-components";

const SvgRoundedCross20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M57.4 50l11.1 11.1c2 2 2 5.3 0 7.4-2 2-5.3 2-7.4 0L50 57.4 38.9 68.5c-2 2-5.3 2-7.4 0-2-2-2-5.3 0-7.4L42.6 50 31.5 38.9c-2-2-2-5.3 0-7.4 2-2 5.3-2 7.4 0L50 42.6l11.1-11.1c2-2 5.3-2 7.4 0 2 2 2 5.3 0 7.4L57.4 50zM50 10c-22.1 0-40 17.9-40 40s17.9 40 40 40 40-17.9 40-40-17.9-40-40-40zm0-10c27.6 0 50 22.4 50 50s-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0z" />
  </svg>
);

export default styled(SvgRoundedCross20)``;
