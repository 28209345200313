import * as React from "react";
import styled from "styled-components";

const SvgPadlock16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 87.5 100" {...props}>
    <path
      className="padlock-16_svg__st0"
      d="M56.2 62.5c0 4.6-2.5 8.6-6.2 10.8V75c0 3.5-2.8 6.2-6.2 6.2s-6.2-2.8-6.2-6.2v-1.7c-3.7-2.2-6.2-6.2-6.2-10.8C31.4 55.6 37 50 43.9 50s12.3 5.6 12.3 12.5zm12.6-31.3c10.3 0 18.8 8.4 18.8 18.8v31.2c0 10.3-8.4 18.8-18.8 18.8h-50C8.4 100 0 91.6 0 81.2V50c0-10.3 8.4-18.8 18.8-18.8v-5C18.8 11.8 30 0 43.8 0s25 11.8 25 26.2v5zm-37.6-5v5h25v-5c0-7.6-5.6-13.7-12.5-13.7s-12.5 6.2-12.5 13.7zm43.8 55V50c0-3.5-2.8-6.2-6.2-6.2h-50c-3.5 0-6.2 2.8-6.2 6.2v31.2c0 3.4 2.8 6.2 6.2 6.2h50c3.4.1 6.2-2.7 6.2-6.2z"
    />
  </svg>
);

export default styled(SvgPadlock16)``;
