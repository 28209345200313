import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const interpolateDefault = (v: any) => (v && v.default) || v;

const pointsPets = interpolateDefault(
  require("../assets/points-pets.jpg"),
) as string;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Code = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  color: ${colors.primary};
  margin-bottom: 15px;
  text-align: center;
  text-shadow: 5px 5px 0 #FDE5E8;

  @media only screen and (min-width: 769px) {
    ${fontSize("100px")}
    line-height: 120px;
    letter-spacing: -4px;
    margin-top: 15px;
  }

  @media only screen and (max-width: 768px) {
    ${fontSize("60px")}
    letter-spacing: -2px;
    line-height: 72px;
    margin-top: 10px;
  }
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("35px")}
  letter-spacing: -1.5px;
  line-height: 42px;
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

const SubTitle = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}
  letter-spacing: -0.5px;
  line-height: 23px;
  margin-bottom: 30px;
  text-align: center;
`;

const Img = styled.img`
  margin-top: 60px;
  max-width: 100%;
  height: auto;
`;

export const Page404 = ({ hideHomeButton }: { hideHomeButton?: boolean }) => (
  <Container>
    <Code>404</Code>
    <Title>Une erreur est survenue !</Title>
    <SubTitle>
      La page que vous cherchez n’existe pas ou a été supprimée.
    </SubTitle>
    {!hideHomeButton && <Button to="/">Retour à l’accueil</Button>}
    <Img width="340" src={pointsPets} />
  </Container>
);
