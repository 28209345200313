import * as React from "react";
import styled from "styled-components";

const SvgKit40 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M38.2 29.4c-1-1-1-2.6 0-3.6s2.6-1 3.5 0l3.3 3.4c1 1 2.6 1 3.5 0l.3-.3L58 16.1c.8-1.1 2.4-1.4 3.5-.6 1.1.8 1.4 2.4.6 3.5-6.4 9-9.7 13.6-9.9 13.8-2.9 3-7.7 3-10.6 0l-3.4-3.4zM15 50L7 64.1l30.3 7.6L45 56.3 15 50zm0 21.2v14.7l32.5 9V62.5l-7.5 15-25-6.3zm37.5-8.7v32.4l32.5-9V71.2l-25 6.2-7.5-14.9zm35-12.5l-32.1 7 7.3 14.6L93 64.1 87.5 50zm-18.2-9.1L90 45l10 22.5L90 70v20l-37.5 10h-5L10 90V70L0 67.5 10 45l20.7-4.1C27.1 36.5 25 31 25 25 25 11.2 36.2 0 50 0s25 11.2 25 25c0 6-2.1 11.5-5.7 15.9zm-5.8-1.1c4-3.7 6.5-8.9 6.5-14.8 0-11-9-20-20-20s-20 9-20 20 9 20 20 20c5.2 0 9.9-2 13.5-5.2zm-40 7.6L50 52.1l26.5-4.7-11.6-2.3C60.7 48.2 55.6 50 50 50s-10.7-1.8-14.9-4.9l-11.6 2.3z" />
  </svg>
);

export default styled(SvgKit40)``;
