import * as React from "react";
import styled from "styled-components";

const SvgArrowR16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 44 100" {...props}>
    <path d="M11.7 2L42 45.1c2.7 2.7 2.7 7 0 9.7L11.7 98c-2.7 2.7-7 2.7-9.7 0-2.7-2.7-2.7-7 0-9.7L27.5 50 2 11.7C-.7 9-.7 4.7 2 2 3.3.7 5.1 0 6.8 0c1.8 0 3.5.7 4.9 2z" />
  </svg>
);

export default styled(SvgArrowR16)``;
