import React from "react";
import styled from "styled-components";
import { Alert } from "./Alert";
import { Button } from "./Button";
import { IconPlus10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { mediaQuery } from "./lib/media";
import {
  Ads,
  Bold,
  Flex,
  IAdsLinks,
  ILitterItem,
  Kits,
  LittersListItemDefaultTransformer,
  Muted,
  Quantity,
} from "./LittersList";
import { PageTitle } from "./PageTitle";

const PageTitleCustom = styled(PageTitle)`
  text-align: left;

  ${mediaQuery("phablet")`
    > div { text-align: left; }
  `}
`;

const Litter = styled.div`
  border-top: 1px solid ${colors.lightLt};
  margin-top: 20px;
  padding-top: 20px;
`;

const Row = styled.div`
  &:not(:first-child) {
    padding-top: 20px;
    border-top: 1px dotted ${colors.light};
  }

  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const LittersListMobile = ({
  litters,
  newLitterButtonLink,
  loading,
  getCompleteLink,
  adsLinks,
  ItemTransformerComponent: ProvidedItemTransformer,
}: {
  litters: ILitterItem[];
  newLitterButtonLink?: string;
  loading: boolean;
  getCompleteLink: (item: ILitterItem) => string | undefined;
  adsLinks: (item: ILitterItem) => IAdsLinks;
  ItemTransformerComponent: typeof LittersListItemDefaultTransformer;
}) => {
  const ItemTransformer =
    ProvidedItemTransformer || LittersListItemDefaultTransformer;
  return (
    <>
      <PageTitleCustom
        addon={
          newLitterButtonLink ? (
            <Button
              href={newLitterButtonLink}
              icon={<IconPlus10 width="10" fill="white" />}
            />
          ) : null
        }
      >
        Mes déclarations de portées
      </PageTitleCustom>

      {!!litters.length || loading ? (
        <div>
          {litters.map((item) => (
            <ItemTransformer key={item.id} item={item}>
              {(props) => (
                <LittersListMobileItem
                  {...props}
                  adsLinks={adsLinks}
                  getCompleteLink={getCompleteLink}
                />
              )}
            </ItemTransformer>
          ))}
        </div>
      ) : (
        <>
          <Alert>Vous n'avez pas encore de portées déclarées</Alert>
        </>
      )}
    </>
  );
};

const LittersListMobileItem = ({
  item,
  adsLinks,
  getCompleteLink,
}: {
  item: ILitterItem;
  getCompleteLink: (item: ILitterItem) => string | undefined;
  adsLinks: (item: ILitterItem) => IAdsLinks;
}) => {
  const completeLink = getCompleteLink(item);

  return (
    <Litter>
      <Row>
        <Flex flexStart>
          <Quantity>{item.pet.number}</Quantity>
          <div>
            <Bold>{item.pet.breed}</Bold>
            <Muted offset={10}>Nés le {item.pet.dob}</Muted>
          </div>
        </Flex>
      </Row>
      {item.kit && (
        <Row>
          <Kits item={item.kit} />
        </Row>
      )}
      {item.ad && (
        <Row>
          <Ads item={item} adsLinks={adsLinks(item)} />
        </Row>
      )}
      {completeLink && (
        <Row>
          <Button fullwidth mini to={getCompleteLink(item)}>
            Compléter
          </Button>
        </Row>
      )}
    </Litter>
  );
};
