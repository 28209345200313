import React from "react";
import styled, { css } from "styled-components";
import { Button } from "./Button";

const Container = styled.div<{ hasScroll: boolean }>`
  bottom: 15%;
  left: 15px;
  position: fixed;
  right: 15px;

  ${p =>
    p.hasScroll &&
    css`
      bottom: 2%;
    `}
`;

export const CatalogFilterButton = ({
  onClick,
  hasScroll,
}: {
  onClick: () => void;
  hasScroll: boolean;
}) => {
  return (
    <Container hasScroll={!!hasScroll}>
      <Button fullwidth theme="dark" onClick={onClick}>
        Filtrer les produits
      </Button>
    </Container>
  );
};
