import { pointsToCouronnes } from "@royalcanin-fr-loyalty/common";
import { addMonths, format, isBefore } from "date-fns";
import frLocale from "date-fns/locale/fr";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { Button } from "./Button";
import { IconInfo16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { Link } from "./Link";
import { Modal } from "./Modal";
import { ModalHeader } from "./ModalHeader";
import { Tipped } from "./Tipped";
import { Wrapper } from "./Wrapper";

const interpolateDefault = (v: any) => (v && v.default) || v;

const pointsPets = interpolateDefault(
  require("../assets/points-pets.jpg"),
) as string;

const Container = styled.div`
  display: flex;
  position: relative;

  ${mediaQuery("desktop")`
    height: 520px;
  `}

  ${mediaQuery("tablet")`
    padding: 40px 0;
  `}

  &::after {
    background: ${colors.primary};
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    ${mediaQuery("tablet")`
      width: 100%;
    `}

    ${mediaQuery("desktop")`
       width: 50%;
      border-bottom-left-radius: 40px;
    `}
  }
`;

const Wrap = styled(Wrapper)`
  ${mediaQuery("desktop")`
    display: flex;
  `}
`;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

const LeftSubTitle = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("19px")}
  color: ${colors.primary};
  letter-spacing: -0.5px;
  text-align: center;
`;

const LeftTitle = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("35px")}
  color: ${colors.darkDk};
  letter-spacing: -1.5px;
  margin-bottom: 40px;
  margin-top: 15px;
  text-align: center;
  flex: 0 0 auto;
`;

const Pets = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  height: auto;
  flex: 0 0 auto;
`;

const PointsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const RightTitle = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("19px")}
  color: #fff;
  letter-spacing: -0.5px;
  text-align: center;
`;

const Points = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  color: #fff;
  margin-bottom: 15px;

  text-align: center;
  text-shadow: 5px 5px 0 rgba(255, 255, 255, 0.25);

  @media only screen and (min-width: 769px) {
    ${fontSize("100px")}
    line-height: 120px;
    letter-spacing: -4px;
    margin-top: 15px;
  }

  @media only screen and (max-width: 768px) {
    ${fontSize("60px")}
    letter-spacing: -2px;
    line-height: 72px;
    margin-top: 10px;
  }
`;

const Desc = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  color: #fff;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const DescRow = styled.div`
  display: flex;
  align-items: center;
`;

const IconInfo = styled.div`
  margin-left: 10px;
  cursor: pointer;

  svg {
    display: block;
  }
`;

const isExpiresInRange = (date: Date) => {
  return isBefore(date, addMonths(new Date(), 6));
};

export interface IPointHeaderProps {
  currentPoints: string | number;
  nextExpiresPoints?: string;
  nextExpiresDate?: string | null;
  expireData?: React.ComponentProps<typeof ExpirePointsInfo>["expireData"];
}

export const PointsHeader = ({
  currentPoints,
  nextExpiresPoints,
  nextExpiresDate,
  expireData,
}: IPointHeaderProps) => (
  <Container>
    <Wrap>
      <Row center="xs">
        <Col xs={false} sm={false} md={5} lg={5}>
          <IntroContainer>
            <LeftSubTitle>Mon programme de fidélité</LeftSubTitle>
            <LeftTitle>
              Utilisez vos couronnes pour obtenir des cadeaux
            </LeftTitle>
            <Pets src={pointsPets} width={340} alt="" />
          </IntroContainer>
        </Col>
        <Col xs={12} sm={12} md={6} mdOffset={1} lg={6} lgOffset={1}>
          <PointsContainer>
            <RightTitle>Mon solde de couronnes</RightTitle>
            <Points>{pointsToCouronnes(currentPoints)}</Points>
            {nextExpiresDate &&
              isExpiresInRange(new Date(nextExpiresDate)) &&
              currentPoints > 0 && (
                <Desc>
                  <DescRow>
                    {pointsToCouronnes(nextExpiresPoints || "0")} couronnes arrivent bientôt à
                    expiration
                    <IconInfo>
                      <Tipped
                        content={`Le ${format(
                          new Date(nextExpiresDate),
                          "d MMMM yyyy",
                          { locale: frLocale },
                        )}, ${pointsToCouronnes(nextExpiresPoints)} couronnes de votre solde arriveront à échéance. Après cette date, vous ne pourrez plus les utiliser.`}
                      >
                        <IconInfo16 width={16} fill="#fff" />
                      </Tipped>
                    </IconInfo>
                  </DescRow>
                  {!!expireData?.length && (
                    <ExpirePointsInfo expireData={expireData} />
                  )}
                </Desc>
              )}
            {currentPoints > 0 && (
              <Button to="/catalog" theme="white">
                Utiliser mes couronnes
              </Button>
            )}
          </PointsContainer>
        </Col>
      </Row>
    </Wrap>
  </Container>
);

const ExpirePointsInfo = ({
  expireData,
}: {
  expireData: {
    points: string;
    date: string;
  }[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sum = expireData.reduce((acc, item) => acc + Number(item.points), 0);
  return (
    <DescRow>
      <Link
        onClick={() => setIsOpen(true)}
        white
        style={{ margin: "auto", marginTop: 10 }}
      >
        En savoir plus
      </Link>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        maxWidth="sm"
      >
        <>
          <ModalHeader onClose={() => setIsOpen(false)}>
            {pointsToCouronnes(sum)} couronnes arrivent à expiration
          </ModalHeader>
          <TableExpirePointsInfo>
            {expireData.map((item) => (
              <div className="row">
                <div>{pointsToCouronnes(item.points)} couronnes</div>
                <div>Le {format(new Date(item.date), "dd.MM.yyyy")}</div>
              </div>
            ))}
            <div className="actions">
              <Button to="/catalog">Utiliser mes couronnes</Button>
            </div>
          </TableExpirePointsInfo>
        </>
      </Modal>
    </DescRow>
  );
};

const TableExpirePointsInfo = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.lightLt};
    padding: 20px 0;

    &:first-child {
      border-top: 1px solid ${colors.lightLt};
    }

    > div:first-child {
      ${fontFamily(ffdinPro.bold)}
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;
