import styled, { css } from "styled-components";

export function mediaQuery(
  size: "mobile" | "phablet" | "tablet" | "responsive" | "desktop" | "print",
) {
  switch (size) {
    case "mobile":
      return (...args: any) => css`
        /* MOBILE: en dessous et égal à 412  */
        @media only screen and (max-width: 412px) {
          ${(css as any)(...args)}
        }
      `;
    case "phablet":
      return (...args: any) => css`
        /* MOBILE: en dessous et égal à 600  */
        @media only screen and (max-width: 600px) {
          ${(css as any)(...args)}
        }
      `;
    case "tablet":
      return (...args: any) => css`
        /*TABLET:  en dessous et égal à 768 */
        @media only screen and (max-width: 768px) {
          ${(css as any)(...args)}
        }
      `;
    case "responsive":
      return (...args: any) => css`
        /*DESKTOP SM:  en dessous et egal à 960 (pour afficher le menu pas trop tard) */
        @media only screen and (max-width: 960px) {
          ${(css as any)(...args)}
        }
      `;
    case "desktop":
      return (...args: any) => css`
        /* Au dessus de 768 */
        @media only screen and (min-width: 769px) {
          ${(css as any)(...args)}
        }
      `;
    case "print":
      return (...args: any) => css`
        @media only print {
          ${(css as any)(...args)}
        }
      `;
    default:
  }

  return (...args: any) => css`
    ${(css as any)(...args)}
  `;
}

type MediaType = "<412" | "<=768" | ">768" | "<=960" | ">960";

export const Media = styled.div<{ when: MediaType | MediaType[] }>`
  display: none;

  ${props => {
    const when = Array.isArray(props.when) ? props.when : [props.when];
    return when.map(value => {
      switch (value) {
        case "<412":
          return css`
            /* MOBILE: en dessous et egal à 412  */
            @media only screen and (max-width: 412px) {
              display: block;
            }
          `;
        case "<=768":
          return css`
            /*TABLET:  en dessous et egal à 768 */
            @media only screen and (max-width: 768px) {
              display: block;
            }
          `;
        case ">768":
          return css`
            /*TABLET:  au dessus de 768 */
            @media only screen and (min-width: 769px) {
              display: block;
            }
          `;
        case "<=960":
          return css`
            /*DESKTOP SM:  en dessous et egal à 960 (pour afficher le menu pas trop tard) */
            @media only screen and (max-width: 960px) {
              display: block;
            }
          `;
        case ">960":
          return css`
            /* DESKTOP: au dessus de 960 */
            @media only screen and (min-width: 961px) {
              display: block;
            }
          `;
      }
    });
  }}
`;
