import * as React from "react";
import styled from "styled-components";

const SvgBasket16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 94" {...props}>
    <path
      className="basket-16_svg__st0"
      d="M93.7 31.3H78l-23.1-29c-2.4-3-7.4-3-9.8 0L22 31.3H6.3c-1.9 0-3.7.9-4.9 2.4C.2 35.2-.2 37.2.3 39.1l11.3 45.4C13 90.1 18 94 23.7 94h52.7c5.7 0 10.7-3.9 12.1-9.5L99.8 39c.5-1.9.1-3.9-1.1-5.4-1.2-1.5-3-2.3-5-2.3zM82.6 56.4H56.2V43.8h29.5l-3.1 12.6zM50 16.2l12 15.1H38l12-15.1zm-6.2 27.6v12.5H17.4l-3.1-12.5h29.5zM20.5 68.9h23.2v12.5H23.6l-3.1-12.5zm35.7 12.6V68.9h23.2l-3.1 12.5H56.2z"
    />
  </svg>
);

export default styled(SvgBasket16)``;
