import React from "react";
import { MainProvider } from "./src/lib/MainProvider";
import "core-js/modules/es6.set";
import "core-js/modules/es6.map";
import { setupLogicalLink } from "./gatsby-logical-link";
import "./src/lib/yup-locales";
import Helmet from "react-helmet";
import { capitalize } from "lodash";

setupLogicalLink();

export const wrapRootElement = ({ element }) => (
  <MainProvider>{element}</MainProvider>
);

export const wrapPageElement = ({ element, props: { uri = "" } }) => {
  const title = capitalize(uri.split("/").filter(Boolean)[0] || "");

  return (
    <>
      <Helmet
        titleTemplate="%s | Loyalty France | Royal Canin"
        defaultTitle="Mon espace éleveur"
      >
        <title>{title}</title>
      </Helmet>
      {element}
    </>
  );
};

export const onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      pageview(window.location.href);
    }, 50);
  }
};

const pageview = url => {
  const ga = window.ga;
  if (typeof ga !== "function") {
    console.warn("window.ga is not function!");
    return;
  }

  try {
    ga.getAll()[0].send("send", "pageview", url);
  } catch (err) {
    console.warn(err);
  }
};
