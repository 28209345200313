import * as React from "react";
import styled from "styled-components";

const SvgMale16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M34.4 87.5c12.1 0 21.9-9.8 21.9-21.9s-9.8-21.9-21.9-21.9-21.9 9.8-21.9 21.9 9.8 21.9 21.9 21.9zm34.4-21.9c0 19-15.4 34.4-34.4 34.4S0 84.6 0 65.6s15.4-34.4 34.4-34.4c7.2 0 13.8 2.2 19.3 5.9l24.7-24.7H62.5c-3.5 0-6.2-2.8-6.2-6.2S59 0 62.5 0H100v37.5c0 3.5-2.8 6.2-6.2 6.2s-6.2-2.8-6.2-6.2V21.6L62.8 46.3c3.8 5.5 6 12.2 6 19.3z" />
  </svg>
);

export default styled(SvgMale16)``;
