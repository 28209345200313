import * as React from "react";
import styled from "styled-components";

const SvgArrowT10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 50" {...props}>
    <path
      className="arrow-t-10_svg__st0"
      d="M96.8 31.4c2.1 2.1 3.2 4.9 3.2 7.7s-1.1 5.6-3.2 7.7c-4.3 4.2-11.2 4.2-15.5 0L50 26.3 18.7 46.8C14.4 51 7.5 51 3.2 46.8c-4.3-4.3-4.3-11.1 0-15.4l39-28.2c4.3-4.3 11.2-4.3 15.5 0l39.1 28.2z"
    />
  </svg>
);

export default styled(SvgArrowT10)``;
