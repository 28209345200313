import * as React from "react";
import styled from "styled-components";

const SvgEyeClose16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 87.5" {...props}>
    <path d="M98.6 39.8C92.4 32.5 85.1 26.2 77 21.1l9-10.9c2.2-2.7 1.8-6.6-.8-8.8-2.7-2.2-6.6-1.8-8.8.8l-11 13.2c-4.9-1.9-10.1-2.8-15.4-2.9-26 0-47.7 26.1-48.6 27.2-1.9 2.3-1.9 5.6 0 7.9C7.7 55 14.9 61.3 23.1 66.4L14 77.3c-2.2 2.7-1.8 6.6.8 8.8s6.6 1.8 8.8-.8l11-13.2c4.9 1.8 10.1 2.8 15.4 2.8 26 0 47.7-26.1 48.6-27.2 1.9-2.3 1.9-5.6 0-7.9zM31.3 56.6c-6.1-3.6-11.6-7.9-16.6-12.8C22 36.3 36.1 25 50 25c2.3 0 4.5.3 6.8.9l-4.6 5.6c-.7-.1-1.4-.2-2.1-.2-6.9 0-12.5 5.6-12.5 12.5 0 1.5.3 3 .8 4.4l-7.1 8.4zM50 62.4c-2.3 0-4.5-.3-6.8-.9l4.6-5.6c.7.1 1.4.2 2.1.2 6.9 0 12.5-5.6 12.5-12.5 0-1.5-.3-3-.8-4.4l7.1-8.5c6.1 3.6 11.6 7.9 16.6 12.8C78 51.2 63.9 62.4 50 62.4z" />
  </svg>
);

export default styled(SvgEyeClose16)``;
