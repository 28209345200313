// tslint:disable:object-literal-sort-keys
export const colors = {
  primaryLt: "#fabfc6",
  primary: "#ec001a",
  primaryDk: "#bf0016",

  lightLter: "#f6f6f6",
  lightLt: "#eaeaea",
  light: "#BFBFBF",

  dark: "#808285",
  darkDk: "#333333",

  success: "#47b800",
  warning: "#ee8b00",
  danger: "red",

  blue: "#41CCD9",
};

export const styledFlexboxGrid = {
  gutterWidth: 1.875,
  outerMargin: 0.9375,
  breakpoints: {
    xs: 0,
    sm: 25.813, // 413px
    md: 48.063, // 769px
    lg: 60, // 960
  },
};
